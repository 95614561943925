const LocationReviewsData = [
    {
        "date": "2024-06-14",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Kelly",
        "service_type": "Garage Door Repair",
        "tech_name": "Victoria S.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.9,
        "customer_name": "Kathleen Vasquez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Daniel H.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.9,
        "customer_name": "Kelsey Moore",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Joseph W.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-22",
        "review_rate": 4.8,
        "customer_name": "Heather Miller",
        "service_type": "Garage Door Services",
        "tech_name": "Grant C.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-24",
        "review_rate": 5.0,
        "customer_name": "Matthew Valdez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Abigail H.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.9,
        "customer_name": "Ryan Erickson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Christopher C.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.9,
        "customer_name": "Kimberly Watkins",
        "service_type": "Garage Door Installation",
        "tech_name": "Julie O.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-03",
        "review_rate": 5.0,
        "customer_name": "Scott White",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jeffery W.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-17",
        "review_rate": 5.0,
        "customer_name": "Suzanne Simon",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Joshua M.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.9,
        "customer_name": "Mark Morgan",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Haley J.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-28",
        "review_rate": 5.0,
        "customer_name": "Walter Blackwell",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Gregory P.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.9,
        "customer_name": "Darren Brown",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Thomas F.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.8,
        "customer_name": "Daniel Stone",
        "service_type": "Garage Door Repair",
        "tech_name": "Rachel S.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.9,
        "customer_name": "Jason Walker",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kimberly J.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-22",
        "review_rate": 5.0,
        "customer_name": "Jessica Powell",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Shane J.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.9,
        "customer_name": "Charles Hall",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "David H.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.8,
        "customer_name": "Michelle York",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jennifer O.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Suzanne Patel",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jennifer A.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.9,
        "customer_name": "Joshua Coleman",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Claudia R.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.9,
        "customer_name": "Barbara Cooper",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Lisa C.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.8,
        "customer_name": "Jennifer Francis",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Tracey J.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.8,
        "customer_name": "Brian Allen",
        "service_type": "Garage Door Off Track",
        "tech_name": "Mary M.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.8,
        "customer_name": "Anthony Rogers",
        "service_type": "Garage Door Repair",
        "tech_name": "Ryan J.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Michelle Moore PhD",
        "service_type": "Garage Door Services",
        "tech_name": "Laura M.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.9,
        "customer_name": "Felicia Holder",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Stephen R.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "Mark Cole",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jessica S.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.8,
        "customer_name": "Brandon Ortega",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Danielle S.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.9,
        "customer_name": "Christine Rice",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Meagan J.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.9,
        "customer_name": "Jasmine King",
        "service_type": "Garage Door Installation",
        "tech_name": "Vanessa L.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.8,
        "customer_name": "Phyllis Cole",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Tanya G.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.9,
        "customer_name": "Caitlin Bishop",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Heather W.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.8,
        "customer_name": "Samantha Martinez",
        "service_type": "Garage Door Services",
        "tech_name": "Phyllis P.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.9,
        "customer_name": "Melissa Jackson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Joseph J.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.8,
        "customer_name": "Karen Knight",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jasmine P.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "Laurie Austin",
        "service_type": "Garage Door Repair",
        "tech_name": "Jamie M.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.9,
        "customer_name": "Amy Wong",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jamie W.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.8,
        "customer_name": "Jeanne Johnson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Robert W.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.9,
        "customer_name": "Michael Reed",
        "service_type": "Garage Door Opener",
        "tech_name": "Heidi J.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.8,
        "customer_name": "Patricia Rivera",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Anne L.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.9,
        "customer_name": "Joseph Jackson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Marcus T.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.9,
        "customer_name": "Sandra Salazar",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jessica H.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.8,
        "customer_name": "Michael Henderson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Anne M.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-14",
        "review_rate": 5.0,
        "customer_name": "Miranda Miller",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jeffrey B.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.9,
        "customer_name": "Anthony Meyer",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Lisa J.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.9,
        "customer_name": "Alex Crosby",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Anna C.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.9,
        "customer_name": "Brandy Cook",
        "service_type": "Garage Door Off Track",
        "tech_name": "Bethany B.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-20",
        "review_rate": 5.0,
        "customer_name": "Linda Dominguez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jessica J.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.8,
        "customer_name": "Mary Carr",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Elizabeth J.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.9,
        "customer_name": "Sarah Kelley",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Megan S.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.8,
        "customer_name": "Michael Steele",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Antonio N.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.8,
        "customer_name": "Cynthia Gonzalez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Tina K.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.9,
        "customer_name": "Daniel Collins",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Michael W.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.9,
        "customer_name": "Barbara Smith",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Marvin H.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.9,
        "customer_name": "Lisa Jones",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Crystal G.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Michael Guzman",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Susan S.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.9,
        "customer_name": "Jerry Jones",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Matthew B.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.8,
        "customer_name": "Marcia Reese",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Alex M.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-25",
        "review_rate": 5.0,
        "customer_name": "Rebecca Robbins",
        "service_type": "Garage Door Installation",
        "tech_name": "Donna M.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-09-27",
        "review_rate": 5.0,
        "customer_name": "Warren Hanson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Rebecca B.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.8,
        "customer_name": "Shane Evans",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Shannon B.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-26",
        "review_rate": 5.0,
        "customer_name": "Michael Smith",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jessica S.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.9,
        "customer_name": "Dana Kline",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Dana R.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.9,
        "customer_name": "Nicholas Dickerson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jennifer H.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.9,
        "customer_name": "Krystal Williams",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Joseph C.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.9,
        "customer_name": "Nicole Mitchell",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Victor W.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.9,
        "customer_name": "Angela Weber",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Nicole R.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-21",
        "review_rate": 4.8,
        "customer_name": "Valerie Valdez DDS",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Ronald C.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.8,
        "customer_name": "Andrew Martinez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jack F.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-30",
        "review_rate": 5.0,
        "customer_name": "Gregory Martinez",
        "service_type": "Garage Door Services",
        "tech_name": "Carla T.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-13",
        "review_rate": 5.0,
        "customer_name": "Amanda Rubio DVM",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jennifer G.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.9,
        "customer_name": "Stephanie Vincent",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Katelyn S.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "Scott Rodgers",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Karen O.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.9,
        "customer_name": "Ashley Townsend",
        "service_type": "Garage Door Installation",
        "tech_name": "Andrew R.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-28",
        "review_rate": 5.0,
        "customer_name": "Jordan Lin",
        "service_type": "Garage Door Off Track",
        "tech_name": "Christina W.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.8,
        "customer_name": "Katherine Henry",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Elizabeth P.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.9,
        "customer_name": "Sergio Lee",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Troy S.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.8,
        "customer_name": "Michael Miller",
        "service_type": "Garage Door Installation",
        "tech_name": "Mia W.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.8,
        "customer_name": "Brittany Cummings",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Anita C.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-07",
        "review_rate": 5.0,
        "customer_name": "Alejandro Wade",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jake E.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-16",
        "review_rate": 5.0,
        "customer_name": "Ryan Guerra",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Megan M.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-20",
        "review_rate": 5.0,
        "customer_name": "Alicia Ortiz",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Deborah D.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.9,
        "customer_name": "Michaela Porter",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Denise C.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.9,
        "customer_name": "Jonathan Campos",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Timothy H.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Roy Mendez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Robert N.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-23",
        "review_rate": 4.9,
        "customer_name": "James Cervantes",
        "service_type": "Residential Garage Door Services",
        "tech_name": "John C.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.8,
        "customer_name": "Amy Koch",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jacqueline W.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.8,
        "customer_name": "Mark Nguyen MD",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Scott B.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.9,
        "customer_name": "Christopher Roberts",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Cheryl L.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.9,
        "customer_name": "Jesus Curry",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jeffrey C.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.8,
        "customer_name": "Jared Reese",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Ralph W.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-28",
        "review_rate": 5.0,
        "customer_name": "Samantha Gomez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Lindsey G.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.9,
        "customer_name": "Jerry Miller",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Richard R.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-27",
        "review_rate": 5.0,
        "customer_name": "Jeffrey Morris",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Catherine S.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.9,
        "customer_name": "Jonathan Mcfarland",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Robert A.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.9,
        "customer_name": "Luke Lewis",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Charles P.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.9,
        "customer_name": "Mrs. Taylor Heath DDS",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Gary B.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-05",
        "review_rate": 5.0,
        "customer_name": "Paul Ortega",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Danielle A.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.9,
        "customer_name": "Christine Lynch",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Erin B.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.9,
        "customer_name": "Robert Brady",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Savannah A.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.9,
        "customer_name": "Christopher Johnson",
        "service_type": "Garage Door Repair",
        "tech_name": "Victoria W.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.9,
        "customer_name": "Timothy Harris",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Michael B.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Daniel Thomas",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Joseph S.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.8,
        "customer_name": "George Scott",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Joshua S.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.9,
        "customer_name": "Joyce Thompson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Lauren J.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.8,
        "customer_name": "Hailey Armstrong",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Henry K.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-16",
        "review_rate": 5.0,
        "customer_name": "George Sparks",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Roger V.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.9,
        "customer_name": "Amanda Davis",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Elizabeth B.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-14",
        "review_rate": 5.0,
        "customer_name": "Michael Atkins",
        "service_type": "Garage Door Installation",
        "tech_name": "William W.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.8,
        "customer_name": "Jacob James",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Paula L.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-11",
        "review_rate": 5.0,
        "customer_name": "David Simmons",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jessica D.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.9,
        "customer_name": "Kyle Garcia",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jason C.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-03",
        "review_rate": 5.0,
        "customer_name": "Joseph Pace",
        "service_type": "Garage Door Installation",
        "tech_name": "Tara P.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "David Owens",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Mariah W.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.9,
        "customer_name": "Laura Hernandez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Susan W.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-24",
        "review_rate": 5.0,
        "customer_name": "Christina Hunter",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Alexander J.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.8,
        "customer_name": "Ashley Garcia",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Bryan V.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Daniel Schmidt",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jeanette M.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.8,
        "customer_name": "Debra Hernandez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Kenneth T.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.9,
        "customer_name": "Jonathan Hayes",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Elizabeth W.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.9,
        "customer_name": "Virginia Buckley",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Allison F.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "Amy Martinez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "John H.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-20",
        "review_rate": 5.0,
        "customer_name": "Kathryn Dixon",
        "service_type": "Garage Door Opener",
        "tech_name": "Benjamin W.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.8,
        "customer_name": "Nathaniel Horn",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jessica W.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "William Young",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Elizabeth M.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.9,
        "customer_name": "Mark Foley",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Thomas W.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.9,
        "customer_name": "Michael Mcintosh",
        "service_type": "Garage Door Opener",
        "tech_name": "Lori B.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.9,
        "customer_name": "Catherine Hill",
        "service_type": "Garage Door Off Track",
        "tech_name": "Joseph G.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.8,
        "customer_name": "Denise Crawford",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Caroline B.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.9,
        "customer_name": "Benjamin Tucker",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Erin H.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-21",
        "review_rate": 5.0,
        "customer_name": "Christina Stewart",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Andrew M.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.8,
        "customer_name": "Robin Curry",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Kevin F.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.8,
        "customer_name": "Tiffany Ellis",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jesus C.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-16",
        "review_rate": 5.0,
        "customer_name": "Alisha Mitchell",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jeffrey A.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.9,
        "customer_name": "Gavin Shaffer",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Teresa R.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-03",
        "review_rate": 5.0,
        "customer_name": "Frank Davis",
        "service_type": "Garage Door Installation",
        "tech_name": "Kelly B.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-10",
        "review_rate": 5.0,
        "customer_name": "Katie Barber",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Eric J.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-06",
        "review_rate": 5.0,
        "customer_name": "Tracy Barker",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "David W.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-19",
        "review_rate": 5.0,
        "customer_name": "Rodney Levine",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Justin L.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-21",
        "review_rate": 5.0,
        "customer_name": "Stacey Bender",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jessica W.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-24",
        "review_rate": 5.0,
        "customer_name": "Paul Frazier",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Leslie H.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-19",
        "review_rate": 5.0,
        "customer_name": "Anne Merritt",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Travis W.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-24",
        "review_rate": 5.0,
        "customer_name": "Dr. Jeremy Walker",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Tamara D.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.9,
        "customer_name": "Tara Cole",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Cassandra B.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.9,
        "customer_name": "Jeremy Lopez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Gabriel R.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-01",
        "review_rate": 5.0,
        "customer_name": "Michael Newton",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Michael H.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-28",
        "review_rate": 5.0,
        "customer_name": "Tina Banks",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jillian B.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.8,
        "customer_name": "Keith Hays",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Mary S.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.9,
        "customer_name": "Christian Kaufman",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Juan A.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.8,
        "customer_name": "William Owens",
        "service_type": "Garage Door Insulation",
        "tech_name": "David R.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.9,
        "customer_name": "John Cruz",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Valerie A.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-17",
        "review_rate": 5.0,
        "customer_name": "Charles Anderson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Brandon H.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-15",
        "review_rate": 5.0,
        "customer_name": "Matthew Brandt",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Kimberly B.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.9,
        "customer_name": "Bob Elliott",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Timothy N.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.8,
        "customer_name": "Sarah Clark",
        "service_type": "Garage Door Off Track",
        "tech_name": "Cynthia S.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-04",
        "review_rate": 5.0,
        "customer_name": "Emily Bennett",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Douglas R.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-07",
        "review_rate": 5.0,
        "customer_name": "Jill Greene",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "John C.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.9,
        "customer_name": "Jennifer Webb",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Allison A.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-30",
        "review_rate": 4.9,
        "customer_name": "Tammie Atkins",
        "service_type": "Garage Door Installation",
        "tech_name": "Emily P.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "Leslie Walker",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Michael N.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.8,
        "customer_name": "Emily Myers",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Christina T.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.8,
        "customer_name": "Jeremy Patton",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Spencer A.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Williams",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Howard S.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.8,
        "customer_name": "Andre Roberts",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Helen J.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-25",
        "review_rate": 5.0,
        "customer_name": "Walter Brewer",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Melissa S.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.9,
        "customer_name": "Mikayla Perkins",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Ian G.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.9,
        "customer_name": "Jason Olson",
        "service_type": "Garage Door Installation",
        "tech_name": "Ann Q.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.9,
        "customer_name": "James Daugherty",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Johnny C.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.8,
        "customer_name": "Jill Collins",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Justin J.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.8,
        "customer_name": "Mathew Durham",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Walter L.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.9,
        "customer_name": "Penny Nielsen",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Pamela O.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.9,
        "customer_name": "Ethan Morrow",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Travis H.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.9,
        "customer_name": "Nicole Floyd",
        "service_type": "Garage Door Insulation",
        "tech_name": "Andrea C.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "Arthur Carroll",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "James C.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-01",
        "review_rate": 5.0,
        "customer_name": "Alexis Brown",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Crystal W.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.9,
        "customer_name": "Robert Lawson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Robert J.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.9,
        "customer_name": "Catherine Mills",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Nathan A.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.9,
        "customer_name": "Jennifer Ward",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Sheila G.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-11",
        "review_rate": 5.0,
        "customer_name": "Jennifer West",
        "service_type": "Garage Door Services",
        "tech_name": "Tina W.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-07",
        "review_rate": 5.0,
        "customer_name": "Amy Alvarez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Terri N.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.9,
        "customer_name": "Emily Shaffer",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Stephanie F.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-02",
        "review_rate": 5.0,
        "customer_name": "Alicia Brown",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Maria P.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "Amy Wilson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Thomas P.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.8,
        "customer_name": "Terry Kelley",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Hannah D.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-08",
        "review_rate": 5.0,
        "customer_name": "Steve Jimenez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Mary A.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-02",
        "review_rate": 5.0,
        "customer_name": "Kristin Day",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Brandon M.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-03",
        "review_rate": 5.0,
        "customer_name": "Alyssa Williams",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Allison M.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Mary Ramirez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jeremy C.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-30",
        "review_rate": 5.0,
        "customer_name": "Zachary Larsen",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Thomas B.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.9,
        "customer_name": "James Thompson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Gregory G.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.9,
        "customer_name": "Olivia White",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Timothy F.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-10",
        "review_rate": 5.0,
        "customer_name": "Steven Smith",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Allen H.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.8,
        "customer_name": "Bradley Taylor",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Antonio T.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.8,
        "customer_name": "Matthew Nelson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Tanya W.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.9,
        "customer_name": "James Harris",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jennifer R.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Frank Nelson",
        "service_type": "Garage Door Opener",
        "tech_name": "Madison F.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Levine",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Kathryn C.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.8,
        "customer_name": "Mary Casey",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Debbie P.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-30",
        "review_rate": 5.0,
        "customer_name": "Veronica Lawrence",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jade B.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.9,
        "customer_name": "Stephanie Burns",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Priscilla W.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-06",
        "review_rate": 5.0,
        "customer_name": "Christopher Ward",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Megan P.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-11",
        "review_rate": 5.0,
        "customer_name": "Kelly Aguilar",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Sierra G.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "Christopher Gallegos",
        "service_type": "Garage Door Insulation",
        "tech_name": "Morgan D.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-09-30",
        "review_rate": 5.0,
        "customer_name": "Richard Nelson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Charles J.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.8,
        "customer_name": "Susan Pierce",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Ariel F.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.8,
        "customer_name": "Laurie Miller",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Gary D.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-07",
        "review_rate": 5.0,
        "customer_name": "George Murphy",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Charlene H.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-30",
        "review_rate": 5.0,
        "customer_name": "Anthony Smith",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Thomas W.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-22",
        "review_rate": 5.0,
        "customer_name": "Mitchell Munoz MD",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kelly L.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.9,
        "customer_name": "Leonard Johnson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Dennis N.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.8,
        "customer_name": "Ronnie Carter",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Brittany M.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.9,
        "customer_name": "Thomas Whitehead",
        "service_type": "Garage Door Insulation",
        "tech_name": "Stephanie R.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.8,
        "customer_name": "Shelia Mitchell",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Darren J.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.9,
        "customer_name": "Timothy Dean",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Michael W.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.8,
        "customer_name": "Gregory Johnson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Steven B.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-04",
        "review_rate": 5.0,
        "customer_name": "Jessica Cantu",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Vanessa T.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.9,
        "customer_name": "Natalie Cox",
        "service_type": "Garage Door Repair",
        "tech_name": "Amy J.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.9,
        "customer_name": "Rita Phillips",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Zachary N.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.9,
        "customer_name": "Kenneth Martin",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Donald M.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-23",
        "review_rate": 5.0,
        "customer_name": "Sandra Schneider",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "William A.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.9,
        "customer_name": "Charles Gallegos",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Eric M.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.9,
        "customer_name": "Austin French",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Megan K.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.9,
        "customer_name": "Angel Spencer",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Stephanie F.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-21",
        "review_rate": 5.0,
        "customer_name": "Taylor Watson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Alexander C.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-31",
        "review_rate": 5.0,
        "customer_name": "David Riley",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Margaret S.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.9,
        "customer_name": "Alison Ward",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Richard C.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.9,
        "customer_name": "Christopher Roberts",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Marcus C.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.9,
        "customer_name": "Cody Lopez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jennifer P.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-22",
        "review_rate": 5.0,
        "customer_name": "David Reilly DDS",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Monique W.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.9,
        "customer_name": "Kenneth Melton",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "William M.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.8,
        "customer_name": "Shelley Palmer",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Carlos S.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.9,
        "customer_name": "Melissa Oneill",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Amy D.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.9,
        "customer_name": "Janice Cherry",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Alyssa J.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.8,
        "customer_name": "Michelle Garcia",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Bruce H.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-10",
        "review_rate": 5.0,
        "customer_name": "Melissa Allison",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Samuel P.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.9,
        "customer_name": "Mark Walsh",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Benjamin G.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-23",
        "review_rate": 5.0,
        "customer_name": "Stephen Lopez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Michelle P.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Thomas Smith",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Terri S.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.9,
        "customer_name": "Daniel Black",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Ashley L.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "Robert Gutierrez",
        "service_type": "Garage Door Repair",
        "tech_name": "James M.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.8,
        "customer_name": "Linda Carpenter",
        "service_type": "Garage Door Installation",
        "tech_name": "Jon R.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.8,
        "customer_name": "Jon Smith",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Brenda K.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.9,
        "customer_name": "Samantha Hernandez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Joseph A.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-28",
        "review_rate": 5.0,
        "customer_name": "Dr. Joshua White",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Shane M.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-02",
        "review_rate": 5.0,
        "customer_name": "Mr. Peter Payne Jr.",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Ryan C.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.9,
        "customer_name": "Morgan Martinez",
        "service_type": "Garage Door Services",
        "tech_name": "Jessica F.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-24",
        "review_rate": 5.0,
        "customer_name": "Matthew Hernandez",
        "service_type": "Garage Door Services",
        "tech_name": "Molly A.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.9,
        "customer_name": "Richard Williams",
        "service_type": "Garage Door Installation",
        "tech_name": "Sandy R.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-19",
        "review_rate": 5.0,
        "customer_name": "Gina Williamson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Richard H.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-26",
        "review_rate": 5.0,
        "customer_name": "Nicole Miller",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Vickie N.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.9,
        "customer_name": "Kara Evans",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kimberly S.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.9,
        "customer_name": "Lindsey Barber",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kristina P.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.9,
        "customer_name": "Steven Williams",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Adam W.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-30",
        "review_rate": 5.0,
        "customer_name": "Gregory Martinez",
        "service_type": "Garage Door Opener",
        "tech_name": "Elizabeth R.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.9,
        "customer_name": "Albert Adams",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Devin S.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.9,
        "customer_name": "Jay Mccoy",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jennifer B.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.8,
        "customer_name": "William Harris",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Michelle O.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.8,
        "customer_name": "Melvin Gutierrez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "David S.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.9,
        "customer_name": "Stephen Barnes",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Richard H.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-02",
        "review_rate": 5.0,
        "customer_name": "Jamie Mcclure",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Amy J.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "Jennifer Bryant",
        "service_type": "Garage Door Opener",
        "tech_name": "Madeline N.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-13",
        "review_rate": 5.0,
        "customer_name": "Hannah Mclean",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Sharon S.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.9,
        "customer_name": "Anita Butler",
        "service_type": "Garage Door Opener",
        "tech_name": "Adam C.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-12",
        "review_rate": 5.0,
        "customer_name": "Kevin Ferrell",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Rachael B.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.9,
        "customer_name": "Shannon Blair",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Brenda F.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Amy Ball",
        "service_type": "Garage Door Track Repair",
        "tech_name": "William S.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-30",
        "review_rate": 5.0,
        "customer_name": "Gina Contreras",
        "service_type": "Garage Door Insulation",
        "tech_name": "Michaela G.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.9,
        "customer_name": "Courtney Orozco",
        "service_type": "Garage Door Off Track",
        "tech_name": "Courtney P.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-20",
        "review_rate": 5.0,
        "customer_name": "Adam Kelley",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Susan S.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.9,
        "customer_name": "Stefanie Thomas",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Angela A.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.9,
        "customer_name": "Miranda Miller",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Joseph C.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Kathryn Berry",
        "service_type": "Garage Door Repair",
        "tech_name": "Paul M.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.9,
        "customer_name": "Matthew Gordon",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Miguel J.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-25",
        "review_rate": 5.0,
        "customer_name": "Patrick Allen",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jesse G.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.9,
        "customer_name": "Connie Walter",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Mike H.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Pamela Brown",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Emily G.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.8,
        "customer_name": "Danielle Foster",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Andrew R.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Katie Meyer",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Alan B.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "Ashley Hines",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Nicolas Y.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-05",
        "review_rate": 5.0,
        "customer_name": "Sierra Bailey",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "David N.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-09",
        "review_rate": 5.0,
        "customer_name": "Christina Alvarez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Kenneth R.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.9,
        "customer_name": "Raymond Hughes",
        "service_type": "Garage Door Installation",
        "tech_name": "Tara M.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.9,
        "customer_name": "John Gallagher",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Karen T.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.8,
        "customer_name": "Jeffrey Sullivan",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Ryan W.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.9,
        "customer_name": "Diane Sanchez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Hannah K.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-14",
        "review_rate": 5.0,
        "customer_name": "Kristen Reeves",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Karen A.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.8,
        "customer_name": "Laura Evans",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Ryan J.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.8,
        "customer_name": "Dale Campbell",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Shannon T.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.8,
        "customer_name": "Zachary Wright",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Gina P.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-19",
        "review_rate": 5.0,
        "customer_name": "Dawn Ramsey",
        "service_type": "Garage Door Repair",
        "tech_name": "Tabitha P.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-13",
        "review_rate": 5.0,
        "customer_name": "Catherine Werner",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Kathy W.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-24",
        "review_rate": 5.0,
        "customer_name": "Melanie Jackson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Christopher R.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.9,
        "customer_name": "Christina Jones",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Shawn K.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-08",
        "review_rate": 5.0,
        "customer_name": "Andrea Rojas",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Sandra W.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "Jonathan Smith",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Robert G.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.9,
        "customer_name": "Kevin Koch",
        "service_type": "Garage Door Repair",
        "tech_name": "Michelle S.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.9,
        "customer_name": "Monica Donovan",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Chris W.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.8,
        "customer_name": "Raymond Rivera",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Steven C.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.9,
        "customer_name": "Rachel Nelson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "John G.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.9,
        "customer_name": "Adam Castillo",
        "service_type": "Garage Door Insulation",
        "tech_name": "Michael B.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.9,
        "customer_name": "Keith Marquez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Robert B.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.9,
        "customer_name": "Jeff Holmes",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kylie F.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Emily Craig",
        "service_type": "Garage Door Repair",
        "tech_name": "Anthony S.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-02",
        "review_rate": 5.0,
        "customer_name": "Melissa Barron",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Patrick C.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Rebecca Maddox",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Nicole B.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-12",
        "review_rate": 5.0,
        "customer_name": "Brandon Smith",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Allen E.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.8,
        "customer_name": "Kevin Baird",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Melissa L.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "John Moore",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Andrew G.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.9,
        "customer_name": "Troy Hodges",
        "service_type": "Garage Door Opener",
        "tech_name": "Vincent W.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-29",
        "review_rate": 5.0,
        "customer_name": "Jonathan Henderson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Arthur R.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-26",
        "review_rate": 5.0,
        "customer_name": "Lawrence Ross",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "John T.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-28",
        "review_rate": 5.0,
        "customer_name": "Brandon Molina",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Lisa W.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.8,
        "customer_name": "Dillon Taylor",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Joshua R.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-11",
        "review_rate": 5.0,
        "customer_name": "Kimberly Evans",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Daniel J.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-30",
        "review_rate": 5.0,
        "customer_name": "Jeffrey James",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kenneth M.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-07",
        "review_rate": 5.0,
        "customer_name": "Jonathan Salazar Jr.",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Laura R.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.9,
        "customer_name": "Cheyenne Roy",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Angela W.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.9,
        "customer_name": "Jeffery Mullins",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "John V.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.9,
        "customer_name": "William Garcia",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Michael L.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-01",
        "review_rate": 5.0,
        "customer_name": "Robert Maddox",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jessica L.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.9,
        "customer_name": "Steven Carter",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Eric M.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-26",
        "review_rate": 5.0,
        "customer_name": "Dennis Hurst",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Theresa T.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.9,
        "customer_name": "Amber Clements",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Ryan T.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-10",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Medina",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Nicholas H.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.8,
        "customer_name": "Samantha Howell",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Lisa P.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-28",
        "review_rate": 5.0,
        "customer_name": "Devin Chen",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Stephanie C.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-23",
        "review_rate": 5.0,
        "customer_name": "Sarah Miller",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kim B.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-27",
        "review_rate": 5.0,
        "customer_name": "Vickie Patterson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Barry K.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.8,
        "customer_name": "Deborah Sullivan",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Michele C.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.9,
        "customer_name": "Erin Hodge",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "John R.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-03",
        "review_rate": 5.0,
        "customer_name": "Lucas Garcia MD",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Devin J.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-09",
        "review_rate": 5.0,
        "customer_name": "Ashley Sanchez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Emily P.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-02",
        "review_rate": 5.0,
        "customer_name": "Drew Ashley",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Tim C.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-04",
        "review_rate": 5.0,
        "customer_name": "Kristina Garrett",
        "service_type": "Garage Door Opener",
        "tech_name": "Brian M.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.8,
        "customer_name": "Thomas Wilson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jonathan L.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-11",
        "review_rate": 5.0,
        "customer_name": "Melissa Dixon",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Christopher B.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-11",
        "review_rate": 5.0,
        "customer_name": "Bradley Pope",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Emily S.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.9,
        "customer_name": "Jackson Green",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Erika D.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.9,
        "customer_name": "Crystal Ballard",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Elizabeth B.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.9,
        "customer_name": "Miguel Watson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Billy H.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-21",
        "review_rate": 4.8,
        "customer_name": "Mary Crawford",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Catherine J.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.8,
        "customer_name": "Christopher Flores",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Randy S.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-21",
        "review_rate": 5.0,
        "customer_name": "Kyle Williams",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Sarah H.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-19",
        "review_rate": 5.0,
        "customer_name": "Jeffrey Snyder",
        "service_type": "Garage Door Off Track",
        "tech_name": "Theresa W.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.9,
        "customer_name": "Paul Smith",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Randy H.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.9,
        "customer_name": "David Ryan",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "David C.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-21",
        "review_rate": 5.0,
        "customer_name": "Pamela Cross",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Courtney M.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.9,
        "customer_name": "Charles Hampton",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Michael M.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.8,
        "customer_name": "Andrea Smith",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jill J.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-30",
        "review_rate": 5.0,
        "customer_name": "Michael Nixon",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Tara K.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-16",
        "review_rate": 5.0,
        "customer_name": "Amy Stewart",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Pamela A.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-22",
        "review_rate": 4.9,
        "customer_name": "Glenn Lopez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Laura B.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.9,
        "customer_name": "Jennifer Andrews",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Sheila G.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.9,
        "customer_name": "James Reid",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Erika M.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.8,
        "customer_name": "Leslie Wilson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Christian G.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.9,
        "customer_name": "Donna Baird",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Rebekah B.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.8,
        "customer_name": "Devon Kim",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Marie D.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.8,
        "customer_name": "Cassandra Richardson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Stephen W.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.8,
        "customer_name": "David Miller",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Leslie P.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.9,
        "customer_name": "Tina Kerr",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Dale G.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.9,
        "customer_name": "Tracy Elliott",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jonathan W.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.8,
        "customer_name": "Heather Larsen",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Eric M.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-05",
        "review_rate": 5.0,
        "customer_name": "Angela Ochoa",
        "service_type": "Garage Door Opener",
        "tech_name": "Troy M.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.9,
        "customer_name": "Marc Murillo",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Rachel S.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.9,
        "customer_name": "Ray Terrell",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Bradley H.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.9,
        "customer_name": "Olivia Smith",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jennifer J.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.9,
        "customer_name": "Barbara Robertson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Theresa G.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-03",
        "review_rate": 5.0,
        "customer_name": "Angela Brown",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Timothy H.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.8,
        "customer_name": "Anthony Parsons",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "April W.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-17",
        "review_rate": 5.0,
        "customer_name": "Robert Butler",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Steven C.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.8,
        "customer_name": "Christine Mendez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Christina H.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.9,
        "customer_name": "Frank Williams",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Tony B.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.9,
        "customer_name": "Bradley Turner",
        "service_type": "Garage Door Opener",
        "tech_name": "Mike M.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-24",
        "review_rate": 5.0,
        "customer_name": "Julia Wells",
        "service_type": "Garage Door Opener",
        "tech_name": "Brian W.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-10",
        "review_rate": 5.0,
        "customer_name": "Amy Gutierrez DDS",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Paul C.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-11",
        "review_rate": 5.0,
        "customer_name": "Levi Joseph",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Melissa W.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-06",
        "review_rate": 5.0,
        "customer_name": "Sheila Bass",
        "service_type": "Garage Door Repair",
        "tech_name": "Ryan A.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.8,
        "customer_name": "George Robertson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Joseph S.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.9,
        "customer_name": "Keith Ford",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Susan Y.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.8,
        "customer_name": "Connie Sloan",
        "service_type": "Garage Door Off Track",
        "tech_name": "Katrina G.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-20",
        "review_rate": 5.0,
        "customer_name": "Yvonne Johnson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Russell H.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.8,
        "customer_name": "Austin Caldwell",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Dale B.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.9,
        "customer_name": "Amanda Andrews",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Cory R.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.9,
        "customer_name": "Arthur Mccann",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Candice P.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.9,
        "customer_name": "Ronald Williams",
        "service_type": "Garage Door Opener",
        "tech_name": "Matthew D.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-03",
        "review_rate": 5.0,
        "customer_name": "Teresa Mcdaniel",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Janet M.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.9,
        "customer_name": "Kevin Hayes",
        "service_type": "Garage Door Opener",
        "tech_name": "Melissa D.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-10",
        "review_rate": 5.0,
        "customer_name": "Tyler Johnson",
        "service_type": "Garage Door Opener",
        "tech_name": "Stephen G.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.8,
        "customer_name": "Derek Wilson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Lori E.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "Caitlin David",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Michael J.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.8,
        "customer_name": "Nathan West",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Spencer H.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-03",
        "review_rate": 5.0,
        "customer_name": "Donna Mccarthy",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Brittany R.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-09",
        "review_rate": 4.9,
        "customer_name": "Michele Brown",
        "service_type": "Garage Door Repair",
        "tech_name": "Ashley S.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "Tara Riggs",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Andrew D.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.8,
        "customer_name": "Scott Thompson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jeffrey F.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.9,
        "customer_name": "Kathryn Carlson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Cindy T.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-19",
        "review_rate": 5.0,
        "customer_name": "Jamie Price",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Daniel M.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Steven Cabrera",
        "service_type": "Garage Door Opener",
        "tech_name": "Cristina S.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-27",
        "review_rate": 5.0,
        "customer_name": "Christopher Beltran",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Desiree E.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.9,
        "customer_name": "Frederick Ross",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jessica S.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.9,
        "customer_name": "Katie Valencia",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Keith M.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.9,
        "customer_name": "Nancy Montgomery",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Maria S.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.8,
        "customer_name": "Larry Cruz",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Caleb S.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-16",
        "review_rate": 5.0,
        "customer_name": "John Montoya",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Stephen C.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Rodriguez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Megan B.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.8,
        "customer_name": "Alyssa Vega",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Reginald M.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.9,
        "customer_name": "Samantha Roberts",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Christina M.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.9,
        "customer_name": "Stephanie Sparks",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Amanda L.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.9,
        "customer_name": "Michael Hebert",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Crystal S.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.8,
        "customer_name": "Cindy Henderson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Melinda W.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.8,
        "customer_name": "Jean Jones",
        "service_type": "Garage Door Insulation",
        "tech_name": "Sarah M.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.8,
        "customer_name": "Samantha Torres",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Sandra M.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.8,
        "customer_name": "Barbara Compton",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Renee T.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.9,
        "customer_name": "Jordan Montes",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "James W.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.9,
        "customer_name": "Brett Smith",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kathy J.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-09",
        "review_rate": 5.0,
        "customer_name": "Melissa Patel",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Shirley F.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.8,
        "customer_name": "Philip Mclaughlin",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Ivan T.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.9,
        "customer_name": "Richard Franklin",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Michael B.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.9,
        "customer_name": "Martin Kennedy",
        "service_type": "Garage Door Opener",
        "tech_name": "Heather S.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-10",
        "review_rate": 5.0,
        "customer_name": "Miguel Hall",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Regina W.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.9,
        "customer_name": "Jennifer Garcia",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Carl C.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-13",
        "review_rate": 5.0,
        "customer_name": "Ashley Hernandez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Wesley L.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Emily Jones",
        "service_type": "Garage Door Opener",
        "tech_name": "Tyler C.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.9,
        "customer_name": "Mr. Michael Byrd",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Daniel J.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.9,
        "customer_name": "Joanne Gomez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Lisa W.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Thompson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Terry B.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-02",
        "review_rate": 5.0,
        "customer_name": "Mrs. Michele Davis",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Eileen W.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.8,
        "customer_name": "Jose Morrow",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Nicole J.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.8,
        "customer_name": "Bryan Camacho",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Amy T.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.9,
        "customer_name": "Austin Smith",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Wendy R.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.8,
        "customer_name": "Amber Foley",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Xavier J.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Daniel Patterson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Joseph R.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-03",
        "review_rate": 5.0,
        "customer_name": "Ryan Alvarez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jessica P.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Joseph Martinez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Meghan R.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.9,
        "customer_name": "Justin Neal",
        "service_type": "Garage Door Repair",
        "tech_name": "Kevin J.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.9,
        "customer_name": "Mr. Ryan Chapman",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "David R.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.8,
        "customer_name": "Andres Jefferson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Matthew P.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.9,
        "customer_name": "Nicholas Gonzales",
        "service_type": "Garage Door Repair",
        "tech_name": "Nicole R.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.9,
        "customer_name": "Gail Mack MD",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Amy S.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.8,
        "customer_name": "Jessica King",
        "service_type": "Garage Door Insulation",
        "tech_name": "Michael O.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.9,
        "customer_name": "Amy Brock",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kristen L.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.8,
        "customer_name": "Michael Mcdowell",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Meghan B.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-24",
        "review_rate": 5.0,
        "customer_name": "Christine Moon",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Cindy M.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Melissa Mason",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Alexander S.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-06",
        "review_rate": 5.0,
        "customer_name": "Patrick Miller",
        "service_type": "Garage Door Services",
        "tech_name": "Susan J.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.9,
        "customer_name": "Eric Flynn",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Alexis G.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.9,
        "customer_name": "Todd Martin",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Amber H.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-05",
        "review_rate": 5.0,
        "customer_name": "David Lopez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Anita R.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.9,
        "customer_name": "Marie Smith",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Penny R.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.8,
        "customer_name": "Shawn Thompson Jr.",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Aaron R.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-27",
        "review_rate": 5.0,
        "customer_name": "Heather Pena",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Dawn W.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.9,
        "customer_name": "Valerie Gordon",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Natasha B.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.9,
        "customer_name": "David Gray",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Alex S.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.8,
        "customer_name": "Gene Little",
        "service_type": "Garage Door Installation",
        "tech_name": "Shirley J.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.9,
        "customer_name": "Rachel Wilson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "William W.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.8,
        "customer_name": "Crystal Ware",
        "service_type": "Garage Door Services",
        "tech_name": "Eugene R.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-13",
        "review_rate": 5.0,
        "customer_name": "John Cabrera",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jason M.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.9,
        "customer_name": "Christopher Ramos",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jesus M.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Brown",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Kenneth W.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.9,
        "customer_name": "Karen Robinson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Ralph P.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.9,
        "customer_name": "Paul Young",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Kathleen F.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-24",
        "review_rate": 5.0,
        "customer_name": "Michael Burke",
        "service_type": "Garage Door Opener",
        "tech_name": "Elizabeth O.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-21",
        "review_rate": 5.0,
        "customer_name": "Sylvia Myers",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Chris C.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.9,
        "customer_name": "Wanda Cobb",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Melinda M.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.8,
        "customer_name": "Shawn Salinas",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Robert G.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.9,
        "customer_name": "Adam Graves",
        "service_type": "Garage Door Installation",
        "tech_name": "Joshua M.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-28",
        "review_rate": 4.9,
        "customer_name": "Danielle Carter",
        "service_type": "Garage Door Insulation",
        "tech_name": "Danny S.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-13",
        "review_rate": 5.0,
        "customer_name": "Claudia Lewis",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Timothy B.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-28",
        "review_rate": 5.0,
        "customer_name": "Randy Gillespie",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Terri W.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.9,
        "customer_name": "Benjamin Peterson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Daniel R.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.9,
        "customer_name": "Kathy Rogers",
        "service_type": "Garage Door Insulation",
        "tech_name": "Joe W.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.9,
        "customer_name": "Andrea Shepard",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Michael K.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.9,
        "customer_name": "Sean Ramirez",
        "service_type": "Garage Door Services",
        "tech_name": "Betty F.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.9,
        "customer_name": "Justin Shah",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jennifer P.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.9,
        "customer_name": "Jeremy Robinson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jason B.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-16",
        "review_rate": 5.0,
        "customer_name": "Jennifer Cruz",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Michael C.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.9,
        "customer_name": "Daniel Sanders",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Seth M.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-22",
        "review_rate": 5.0,
        "customer_name": "Kevin Thornton",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Cheryl D.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-23",
        "review_rate": 5.0,
        "customer_name": "Andrew Scott",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Colleen C.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.8,
        "customer_name": "James Collins",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Mary S.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.9,
        "customer_name": "Linda Lucas",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Bailey A.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Melissa Williams",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Nathaniel S.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-21",
        "review_rate": 5.0,
        "customer_name": "Rebecca Silva",
        "service_type": "Garage Door Off Track",
        "tech_name": "Mary W.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.9,
        "customer_name": "Karen Dixon",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Catherine P.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.9,
        "customer_name": "Joshua Steele",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Christopher F.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.9,
        "customer_name": "Lauren Russell",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Rebecca P.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Rebekah Taylor",
        "service_type": "Garage Door Repair",
        "tech_name": "Patrick F.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Darren Foster",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Katherine M.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-28",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Nelson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Robert M.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.8,
        "customer_name": "Kevin Patel",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Geoffrey W.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.8,
        "customer_name": "Nicole Stevens",
        "service_type": "Custom Garage Door Design",
        "tech_name": "James S.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Steve Lopez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Frank B.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.8,
        "customer_name": "Rebekah Allen",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Calvin B.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-27",
        "review_rate": 5.0,
        "customer_name": "Michael Barry",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Andrew T.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.9,
        "customer_name": "Kevin Mitchell",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Christopher C.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Mrs. Marcia Best",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Garrett N.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.9,
        "customer_name": "Amanda Estrada",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Ashley F.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.9,
        "customer_name": "David Hunter",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Heidi L.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.9,
        "customer_name": "Kara Thomas",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Kristen D.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.9,
        "customer_name": "Richard Adams",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Anthony C.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.9,
        "customer_name": "Ann Parker",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Larry B.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.9,
        "customer_name": "Douglas Huffman",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Heather O.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.8,
        "customer_name": "Brian Rich",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Adrian P.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-11",
        "review_rate": 5.0,
        "customer_name": "Lisa Williams",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Robert K.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-24",
        "review_rate": 5.0,
        "customer_name": "Franklin Campbell",
        "service_type": "Garage Door Repair",
        "tech_name": "Katherine M.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-05",
        "review_rate": 5.0,
        "customer_name": "Samuel Young",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Melissa W.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.8,
        "customer_name": "Jason Friedman",
        "service_type": "Garage Door Services",
        "tech_name": "Stephanie C.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-01",
        "review_rate": 5.0,
        "customer_name": "John Pace",
        "service_type": "Garage Door Off Track",
        "tech_name": "Felicia C.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.9,
        "customer_name": "Dawn Herrera",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Daniel S.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.9,
        "customer_name": "Mr. Joshua Harmon PhD",
        "service_type": "Garage Door Repair",
        "tech_name": "Ronald S.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.9,
        "customer_name": "Jay Suarez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Ashley S.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.8,
        "customer_name": "Michael Johnston",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Zachary S.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.8,
        "customer_name": "Andrew Herrera",
        "service_type": "Garage Door Installation",
        "tech_name": "Mary M.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.8,
        "customer_name": "Jeremy Mendez",
        "service_type": "Garage Door Services",
        "tech_name": "Christian H.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-14",
        "review_rate": 5.0,
        "customer_name": "Mia Ferguson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Samantha M.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.8,
        "customer_name": "Rebecca Padilla",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Robin C.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.8,
        "customer_name": "Veronica Hudson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Matthew R.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.9,
        "customer_name": "Victoria Mahoney",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Beth W.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.9,
        "customer_name": "Jonathan Perez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Loretta B.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.9,
        "customer_name": "Anna Adams",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Joshua N.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.9,
        "customer_name": "Daniel Blevins",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Crystal D.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.8,
        "customer_name": "Isaac Oneal",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Tami B.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-18",
        "review_rate": 5.0,
        "customer_name": "Allen Anderson",
        "service_type": "Garage Door Repair",
        "tech_name": "Shawn B.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.9,
        "customer_name": "Michael Smith",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Monica S.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.9,
        "customer_name": "Norma Hardin",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Heather R.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-25",
        "review_rate": 5.0,
        "customer_name": "Heidi Bond",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Teresa W.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.9,
        "customer_name": "Brian Burns",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Kelly Z.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.9,
        "customer_name": "Brandon Spencer",
        "service_type": "Garage Door Opener",
        "tech_name": "Timothy N.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.9,
        "customer_name": "Danielle Chandler",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Todd S.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.8,
        "customer_name": "Carla Baker",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Michael Y.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-06",
        "review_rate": 5.0,
        "customer_name": "Christopher Frost",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Peter C.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.8,
        "customer_name": "Tyler Barr",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Clinton R.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.8,
        "customer_name": "Harold Brown",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Catherine R.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.8,
        "customer_name": "Timothy Rich",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Ross O.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.9,
        "customer_name": "Daniel Dyer",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Shannon G.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-26",
        "review_rate": 5.0,
        "customer_name": "Oscar Murphy",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Laura H.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.8,
        "customer_name": "Kelly Carlson",
        "service_type": "Garage Door Repair",
        "tech_name": "Cory M.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.9,
        "customer_name": "Miguel Martin",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Andrew C.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.9,
        "customer_name": "Mark Hicks",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Sarah M.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.9,
        "customer_name": "Susan Elliott",
        "service_type": "Garage Door Services",
        "tech_name": "Brenda J.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.9,
        "customer_name": "Dana Roberts",
        "service_type": "Garage Door Repair",
        "tech_name": "Amanda M.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-03",
        "review_rate": 5.0,
        "customer_name": "Todd Pierce",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Zachary B.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.8,
        "customer_name": "Kevin Lane",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Shannon M.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "Matthew Richards",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Valerie R.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.9,
        "customer_name": "Gerald Christian",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "William R.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.8,
        "customer_name": "Evelyn Henry",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Robert H.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.9,
        "customer_name": "Jose Ray",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Matthew J.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.9,
        "customer_name": "Emily Lewis",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Sierra M.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-20",
        "review_rate": 5.0,
        "customer_name": "Krystal Smith",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Rachel G.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "Jesse Jordan",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Shawn T.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.9,
        "customer_name": "Angel Gomez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Corey H.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Barbara Mack",
        "service_type": "Garage Door Installation",
        "tech_name": "Amy C.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.8,
        "customer_name": "Deborah Hawkins",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Emily T.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-25",
        "review_rate": 5.0,
        "customer_name": "Dana Liu",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Carolyn M.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.9,
        "customer_name": "Jessica Garrett",
        "service_type": "Garage Door Off Track",
        "tech_name": "Alicia B.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.9,
        "customer_name": "John Burgess",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Shelby W.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.8,
        "customer_name": "Anthony Herrera",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Paula B.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.8,
        "customer_name": "Lucas Williamson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Thomas J.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.9,
        "customer_name": "Jesus Olson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Lisa W.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.8,
        "customer_name": "James Mann",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Matthew G.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.9,
        "customer_name": "Robert Garcia",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Brandon W.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-18",
        "review_rate": 5.0,
        "customer_name": "Thomas Espinoza",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jacob S.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-13",
        "review_rate": 5.0,
        "customer_name": "Thomas Peters",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Rhonda D.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "Joseph Cummings",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Katherine H.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-30",
        "review_rate": 4.9,
        "customer_name": "Lindsay Cook",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Benjamin G.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.8,
        "customer_name": "Tammy Simmons",
        "service_type": "Garage Door Installation",
        "tech_name": "Natalie S.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.9,
        "customer_name": "Christopher Miller",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Brian B.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.8,
        "customer_name": "James Bender",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Betty H.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.9,
        "customer_name": "Jordan Murray",
        "service_type": "Garage Door Services",
        "tech_name": "Timothy R.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.8,
        "customer_name": "Sharon Ross",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Kevin J.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.8,
        "customer_name": "Scott Taylor",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Maria M.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.9,
        "customer_name": "Erin Mills",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Michael S.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.9,
        "customer_name": "Kevin Medina",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jerry W.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "Chloe Tran",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Lucas M.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.9,
        "customer_name": "Rachel Hicks",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Judith C.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.9,
        "customer_name": "Logan Freeman",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jessica S.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-20",
        "review_rate": 5.0,
        "customer_name": "Heather Wilson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Dana C.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.9,
        "customer_name": "Kirsten Bennett",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Tammy D.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-17",
        "review_rate": 5.0,
        "customer_name": "Justin Griffith",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Amanda R.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.9,
        "customer_name": "Matthew Davis",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kenneth N.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.8,
        "customer_name": "Lonnie Lopez",
        "service_type": "Garage Door Repair",
        "tech_name": "Jason C.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-30",
        "review_rate": 4.9,
        "customer_name": "Tiffany Pearson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Daniel M.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-08",
        "review_rate": 5.0,
        "customer_name": "Nicole Le",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Margaret J.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-03",
        "review_rate": 5.0,
        "customer_name": "Brian White",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Danielle P.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.9,
        "customer_name": "Kristi Hubbard",
        "service_type": "Garage Door Repair",
        "tech_name": "Carrie W.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.8,
        "customer_name": "Morgan Wood",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Victoria C.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-20",
        "review_rate": 5.0,
        "customer_name": "Kathleen Mills",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Christopher C.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.9,
        "customer_name": "Carla Lopez",
        "service_type": "Garage Door Installation",
        "tech_name": "Daniel B.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-13",
        "review_rate": 5.0,
        "customer_name": "Cynthia Mitchell",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Tina C.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.8,
        "customer_name": "Julie Duke",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Stephanie D.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.9,
        "customer_name": "Kathleen Cantu",
        "service_type": "Garage Door Opener",
        "tech_name": "Vincent R.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-24",
        "review_rate": 5.0,
        "customer_name": "David Vincent",
        "service_type": "Garage Door Installation",
        "tech_name": "Wendy F.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.8,
        "customer_name": "Ronald Campbell",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Joseph A.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.9,
        "customer_name": "Michael Gaines",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Rachel F.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-27",
        "review_rate": 5.0,
        "customer_name": "John Hamilton",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Emily E.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.9,
        "customer_name": "Eric Rojas",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Thomas P.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.9,
        "customer_name": "Dawn Lee",
        "service_type": "Garage Door Opener",
        "tech_name": "Shane L.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.9,
        "customer_name": "Ernest Cox",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Kevin S.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.8,
        "customer_name": "Tiffany Blair",
        "service_type": "Garage Door Off Track",
        "tech_name": "Eric M.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.9,
        "customer_name": "Veronica Evans",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jennifer F.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-10",
        "review_rate": 5.0,
        "customer_name": "Tammy Grimes",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Timothy M.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.8,
        "customer_name": "Shelley Martinez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Zachary H.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "Audrey Oliver",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Shawn P.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.9,
        "customer_name": "David Ellis",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Connor M.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.8,
        "customer_name": "James Walker",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Amanda J.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-22",
        "review_rate": 4.9,
        "customer_name": "Lauren Brown",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jeffrey M.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-17",
        "review_rate": 5.0,
        "customer_name": "Robert Thomas",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Ricardo H.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.9,
        "customer_name": "Kelly Ramirez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Diane H.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Jimmy Stephenson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "James G.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.9,
        "customer_name": "Angela Myers",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Brenda M.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-14",
        "review_rate": 5.0,
        "customer_name": "Stephanie Carr",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jennifer R.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-28",
        "review_rate": 5.0,
        "customer_name": "Jay Alvarez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Michael G.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.9,
        "customer_name": "Kelsey Steele",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Kimberly M.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.8,
        "customer_name": "Kenneth Cross",
        "service_type": "Garage Door Services",
        "tech_name": "Joseph F.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.9,
        "customer_name": "Kayla Williams",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Travis M.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.8,
        "customer_name": "James Smith",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jeremy J.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.8,
        "customer_name": "James Hernandez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Anna V.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.8,
        "customer_name": "Anthony Smith",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Phillip M.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.9,
        "customer_name": "Kimberly Frazier",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Molly S.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.9,
        "customer_name": "Sarah Ford",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Travis O.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.9,
        "customer_name": "Katherine Hayes",
        "service_type": "Garage Door Installation",
        "tech_name": "James M.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-31",
        "review_rate": 5.0,
        "customer_name": "Gabriella Meza",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jacob J.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.9,
        "customer_name": "Melissa Stevens",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Julie N.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-07",
        "review_rate": 5.0,
        "customer_name": "William Robinson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Julie M.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-16",
        "review_rate": 5.0,
        "customer_name": "Brenda Morales",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Carlos G.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.9,
        "customer_name": "Hector Ochoa",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Christina V.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.9,
        "customer_name": "Theresa Johnson",
        "service_type": "Garage Door Services",
        "tech_name": "Elizabeth D.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.9,
        "customer_name": "Richard Torres",
        "service_type": "Garage Door Opener",
        "tech_name": "John J.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-30",
        "review_rate": 4.9,
        "customer_name": "Alexander Dixon",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Tiffany S.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.9,
        "customer_name": "Carrie Steele",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Seth M.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.9,
        "customer_name": "John Valencia",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kristin Y.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.9,
        "customer_name": "Jacob Lopez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Brandi S.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.9,
        "customer_name": "Gary Cooper",
        "service_type": "Garage Door Services",
        "tech_name": "Ethan A.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.9,
        "customer_name": "Meghan Owens",
        "service_type": "Garage Door Opener",
        "tech_name": "Theodore R.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "Debra Hahn",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Brittany J.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.9,
        "customer_name": "Ryan Green",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Justin K.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.8,
        "customer_name": "Lauren Gomez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jim S.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.9,
        "customer_name": "Mariah Sanchez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jennifer W.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.8,
        "customer_name": "John Obrien",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Catherine C.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.9,
        "customer_name": "Edward Webb",
        "service_type": "Garage Door Installation",
        "tech_name": "Elizabeth G.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-17",
        "review_rate": 5.0,
        "customer_name": "Kevin Brock",
        "service_type": "Garage Door Services",
        "tech_name": "Eric H.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-13",
        "review_rate": 5.0,
        "customer_name": "Rachel Gross",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Michael L.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-28",
        "review_rate": 5.0,
        "customer_name": "Thomas Guzman",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Brooke A.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.9,
        "customer_name": "Joe Cameron",
        "service_type": "Garage Door Maintenance",
        "tech_name": "John V.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-24",
        "review_rate": 5.0,
        "customer_name": "Louis Ross MD",
        "service_type": "Garage Door Insulation",
        "tech_name": "Amanda A.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-20",
        "review_rate": 5.0,
        "customer_name": "Kimberly Hebert",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Alexander F.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.8,
        "customer_name": "Danielle Terrell",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Felicia M.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.9,
        "customer_name": "Michael Hicks",
        "service_type": "Garage Door Off Track",
        "tech_name": "David M.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-07",
        "review_rate": 5.0,
        "customer_name": "Stacy Phillips",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Victoria R.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.8,
        "customer_name": "Tracy Harris",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Samantha P.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.8,
        "customer_name": "Amanda Davis",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "James C.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.8,
        "customer_name": "Cheryl Nicholson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Justin D.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.8,
        "customer_name": "Andrea Alexander",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Tanner D.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-07",
        "review_rate": 5.0,
        "customer_name": "Stephanie Christensen",
        "service_type": "Garage Door Repair",
        "tech_name": "Candice W.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.8,
        "customer_name": "Jeremy Logan",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "David H.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.9,
        "customer_name": "Jeremy Williams",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Gerald L.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.9,
        "customer_name": "Victoria Joseph",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Zachary W.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-21",
        "review_rate": 5.0,
        "customer_name": "Patrick Powell",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Noah R.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-04",
        "review_rate": 5.0,
        "customer_name": "Stefanie Anderson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kelsey O.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-15",
        "review_rate": 5.0,
        "customer_name": "Mrs. Kari Harris MD",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Gerald B.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.9,
        "customer_name": "Jennifer Roberts",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Melissa R.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-22",
        "review_rate": 5.0,
        "customer_name": "David Oconnor",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Stephanie C.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Matthew Mccann",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Patricia T.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "Melinda Murphy",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Bradley P.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-08",
        "review_rate": 5.0,
        "customer_name": "Theresa Greene",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Anthony S.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-01",
        "review_rate": 5.0,
        "customer_name": "Matthew Dunn",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Laura B.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.9,
        "customer_name": "Haley Young",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jonathan C.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-18",
        "review_rate": 5.0,
        "customer_name": "Lisa Gordon",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Joseph P.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.8,
        "customer_name": "Jamie Franklin",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Melissa S.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.9,
        "customer_name": "Danielle Taylor",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Nicole G.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-01",
        "review_rate": 5.0,
        "customer_name": "Kristina Martin",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Joseph G.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.9,
        "customer_name": "Michelle Webb",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Timothy S.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-11",
        "review_rate": 5.0,
        "customer_name": "Chris Scott",
        "service_type": "Garage Door Services",
        "tech_name": "Gabriel R.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.8,
        "customer_name": "Jennifer May",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Kimberly M.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.8,
        "customer_name": "Linda Moore",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Brian E.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.8,
        "customer_name": "Cynthia Steele",
        "service_type": "Garage Door Maintenance",
        "tech_name": "George W.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.8,
        "customer_name": "Amanda Smith",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jessica B.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-23",
        "review_rate": 5.0,
        "customer_name": "Charles Summers",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Brittany P.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.9,
        "customer_name": "Tracey Fletcher",
        "service_type": "Garage Door Off Track",
        "tech_name": "Ralph Z.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-28",
        "review_rate": 5.0,
        "customer_name": "Ashley Lowe",
        "service_type": "Garage Door Repair",
        "tech_name": "Zachary J.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-16",
        "review_rate": 5.0,
        "customer_name": "Cathy Frost",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Gabrielle W.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-07",
        "review_rate": 4.9,
        "customer_name": "Gregory Reynolds",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Joseph F.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.9,
        "customer_name": "Kathryn Phillips",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Timothy S.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.9,
        "customer_name": "Alicia Montoya",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Thomas C.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.9,
        "customer_name": "Matthew Benjamin",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Beth W.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.9,
        "customer_name": "Dr. Stephen Warren",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "David R.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "Kyle Gutierrez MD",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Antonio D.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.9,
        "customer_name": "Patrick Smith",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Hayley T.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.8,
        "customer_name": "Jenna Ramirez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Timothy S.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.8,
        "customer_name": "Sherri Obrien",
        "service_type": "Garage Door Insulation",
        "tech_name": "Diane K.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.9,
        "customer_name": "Nicole Tran",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Megan M.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.9,
        "customer_name": "Jack Nelson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Kenneth M.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-30",
        "review_rate": 5.0,
        "customer_name": "Allison Jefferson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Ryan A.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.9,
        "customer_name": "Penny Lee",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Rachel B.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.8,
        "customer_name": "Chase Williams",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Matthew A.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.8,
        "customer_name": "Nathan Farley",
        "service_type": "Garage Door Opener",
        "tech_name": "Diane F.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-21",
        "review_rate": 5.0,
        "customer_name": "Robert Green",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Adam S.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-03",
        "review_rate": 5.0,
        "customer_name": "Christopher Brown",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Samantha M.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.9,
        "customer_name": "Joy Baird",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Chelsea H.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-09",
        "review_rate": 5.0,
        "customer_name": "Kevin Gonzales",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Christopher C.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-21",
        "review_rate": 5.0,
        "customer_name": "Brian Garcia",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Anthony J.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.8,
        "customer_name": "Morgan Higgins",
        "service_type": "Garage Door Off Track",
        "tech_name": "Michael G.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Brittany Fox",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kevin S.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.9,
        "customer_name": "Alexander Watson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Tiffany F.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.9,
        "customer_name": "Traci Lopez",
        "service_type": "Garage Door Services",
        "tech_name": "Gregory S.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-29",
        "review_rate": 5.0,
        "customer_name": "Thomas Johnson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Chad R.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-24",
        "review_rate": 5.0,
        "customer_name": "Scott Ortiz",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Donald F.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-18",
        "review_rate": 5.0,
        "customer_name": "Gail Smith",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Sean W.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.9,
        "customer_name": "Dennis Harris",
        "service_type": "Garage Door Off Track",
        "tech_name": "Ashley S.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.8,
        "customer_name": "Kathryn Strickland",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Chase H.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-06",
        "review_rate": 5.0,
        "customer_name": "Christine Hall",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Lynn H.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.9,
        "customer_name": "Rebecca Mcdowell",
        "service_type": "Garage Door Services",
        "tech_name": "Wesley G.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.9,
        "customer_name": "John Villegas",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Wendy G.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "Thomas Lewis",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Stephanie M.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.9,
        "customer_name": "Dawn Greene",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Stanley S.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-06",
        "review_rate": 5.0,
        "customer_name": "Deborah Parker",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Heather P.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.8,
        "customer_name": "Lisa Walters",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Timothy S.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.9,
        "customer_name": "Traci Johns",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "David R.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-24",
        "review_rate": 5.0,
        "customer_name": "Michael Nelson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Kayla C.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.8,
        "customer_name": "Denise Kelly",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Michele A.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-27",
        "review_rate": 5.0,
        "customer_name": "Jacqueline Palmer",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Melinda E.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-09",
        "review_rate": 5.0,
        "customer_name": "Shannon Barrera",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Karen C.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-21",
        "review_rate": 5.0,
        "customer_name": "Kimberly Smith",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Angela L.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.9,
        "customer_name": "Douglas Harris",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Sandra J.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.9,
        "customer_name": "Daisy Bush",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Molly M.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.9,
        "customer_name": "Jessica Johnson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Kristi C.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.9,
        "customer_name": "Kelly Hernandez MD",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Tony A.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-10",
        "review_rate": 5.0,
        "customer_name": "Gary Jackson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Ashley F.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.8,
        "customer_name": "Sarah Fletcher",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jose M.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.9,
        "customer_name": "Edward Montoya",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Brandon T.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.9,
        "customer_name": "Cheryl Ortiz",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Peggy P.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.9,
        "customer_name": "Katherine Brock",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Christopher H.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.9,
        "customer_name": "Victoria Fields",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Ryan R.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.9,
        "customer_name": "Jessica Jones",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jacob V.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.9,
        "customer_name": "Ashley Reed",
        "service_type": "Garage Door Opener",
        "tech_name": "Lauren L.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-14",
        "review_rate": 5.0,
        "customer_name": "Robert Wilkerson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Charles T.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.8,
        "customer_name": "Benjamin Blankenship",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Rebecca M.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.9,
        "customer_name": "Jason Snyder",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Russell C.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.8,
        "customer_name": "Kenneth Coleman",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kevin K.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.8,
        "customer_name": "Travis Moody",
        "service_type": "Garage Door Repair",
        "tech_name": "John D.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.8,
        "customer_name": "Nicole Palmer",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Andrea C.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.8,
        "customer_name": "Matthew Wells",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Wendy B.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-09",
        "review_rate": 5.0,
        "customer_name": "Eric Williamson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Donna K.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-25",
        "review_rate": 5.0,
        "customer_name": "Mrs. Stefanie Long PhD",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jack P.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-13",
        "review_rate": 5.0,
        "customer_name": "Timothy Hamilton",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Christopher C.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-07",
        "review_rate": 5.0,
        "customer_name": "Louis Watson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Timothy J.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-03",
        "review_rate": 5.0,
        "customer_name": "Eric Smith",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Cynthia Y.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.8,
        "customer_name": "Gary Swanson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Stephen B.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.9,
        "customer_name": "Kenneth Rivas",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Kevin W.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Catherine White",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Kevin K.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.8,
        "customer_name": "Matthew Powers",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Ryan B.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.8,
        "customer_name": "Brandon Cortez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Katie S.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-04",
        "review_rate": 5.0,
        "customer_name": "Stephen Bender",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Daniel D.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.9,
        "customer_name": "Jake Wilkins",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Cynthia H.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Colon",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Keith R.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-22",
        "review_rate": 4.9,
        "customer_name": "Cynthia Franco",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kimberly K.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.8,
        "customer_name": "Jonathon Reed",
        "service_type": "Garage Door Services",
        "tech_name": "Joshua D.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.9,
        "customer_name": "Gary Stephens",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Katherine M.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.9,
        "customer_name": "Thomas Young",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Thomas S.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-23",
        "review_rate": 5.0,
        "customer_name": "Anthony Taylor",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Robert N.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.9,
        "customer_name": "Tammy Barnes",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Autumn C.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "William Thomas",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Anne S.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-09-28",
        "review_rate": 5.0,
        "customer_name": "Dawn Weiss",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Lori R.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-15",
        "review_rate": 5.0,
        "customer_name": "Monique Jones",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Mary S.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-02",
        "review_rate": 5.0,
        "customer_name": "Mark Davis",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Anthony V.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-19",
        "review_rate": 5.0,
        "customer_name": "Mrs. Ashley Turner",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Matthew S.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.9,
        "customer_name": "John Phillips",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Francisco D.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-12",
        "review_rate": 5.0,
        "customer_name": "Steven Kim",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Wendy K.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.9,
        "customer_name": "Melissa Hall",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Anthony T.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.9,
        "customer_name": "Russell Blevins",
        "service_type": "Garage Door Opener",
        "tech_name": "Gregory M.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.8,
        "customer_name": "Kimberly Cohen",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Sarah T.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "Tommy Gomez",
        "service_type": "Garage Door Repair",
        "tech_name": "Emily K.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.9,
        "customer_name": "Gregory Brandt",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Michelle W.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.8,
        "customer_name": "Mr. Jonathan Bryan",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Brandon G.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.8,
        "customer_name": "Robert Baker",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jennifer R.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.8,
        "customer_name": "Clifford Barnes",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Tracy R.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "Dr. Kevin Jones",
        "service_type": "Garage Door Repair",
        "tech_name": "Sheri J.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.8,
        "customer_name": "Kristin Jackson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Megan L.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.9,
        "customer_name": "Caleb Becker",
        "service_type": "Garage Door Services",
        "tech_name": "Danielle E.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.9,
        "customer_name": "Jonathan Turner",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Steven W.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.8,
        "customer_name": "Paul Mccall",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Elizabeth W.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.8,
        "customer_name": "Amy Stokes",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Matthew M.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.8,
        "customer_name": "Angelica Oconnell",
        "service_type": "Garage Door Services",
        "tech_name": "Mindy D.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-04",
        "review_rate": 5.0,
        "customer_name": "Sierra Martinez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Bryan H.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-03",
        "review_rate": 5.0,
        "customer_name": "Jerry Barnes",
        "service_type": "Garage Door Services",
        "tech_name": "Jenna S.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-29",
        "review_rate": 5.0,
        "customer_name": "Brandon Roman",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Theresa B.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.8,
        "customer_name": "Richard Fitzgerald",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Alejandra P.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.8,
        "customer_name": "Kathryn Brown",
        "service_type": "Garage Door Repair",
        "tech_name": "Jacqueline L.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-13",
        "review_rate": 5.0,
        "customer_name": "Sabrina Wise",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Kelly D.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.9,
        "customer_name": "Abigail Lewis",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Lisa H.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.9,
        "customer_name": "Kristina Johnson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Ryan B.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.8,
        "customer_name": "Rachel Marsh",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Bonnie S.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-02",
        "review_rate": 5.0,
        "customer_name": "Luis Thomas",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Leah B.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.8,
        "customer_name": "Robert Elliott",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Patricia J.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-20",
        "review_rate": 5.0,
        "customer_name": "Kelly Garrison",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jason W.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.9,
        "customer_name": "Ann Jacobs",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Debra L.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.9,
        "customer_name": "Sandra Wood",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Christopher S.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.8,
        "customer_name": "Wendy Ewing",
        "service_type": "Garage Door Off Track",
        "tech_name": "Brianna O.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.9,
        "customer_name": "Robert Wilson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Joseph H.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.8,
        "customer_name": "Lori Harris",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Sheila S.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.9,
        "customer_name": "James Mckay",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Richard M.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-14",
        "review_rate": 5.0,
        "customer_name": "Andrew Garrett",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Tina E.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.9,
        "customer_name": "Emily Owens",
        "service_type": "Garage Door Services",
        "tech_name": "Katelyn F.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-02",
        "review_rate": 4.9,
        "customer_name": "Gail King",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Darrell L.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Sherry Sanchez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Michael F.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Rebecca White",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Bethany J.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.9,
        "customer_name": "Joshua Koch",
        "service_type": "Garage Door Installation",
        "tech_name": "Stacy M.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-28",
        "review_rate": 5.0,
        "customer_name": "Crystal Foster",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Patricia C.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "Stephanie Cunningham",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Michael N.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.8,
        "customer_name": "Robert Baker",
        "service_type": "Garage Door Off Track",
        "tech_name": "Ross W.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.9,
        "customer_name": "Garrett Walker",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Rachel O.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.8,
        "customer_name": "Ryan Anderson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Tom W.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.9,
        "customer_name": "Nicholas Stanley",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Brittany J.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "Dennis Franco",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Rita A.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.9,
        "customer_name": "Don Adkins",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Phyllis R.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.9,
        "customer_name": "Christopher Torres MD",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Michelle C.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "Heather Moore",
        "service_type": "Garage Door Repair",
        "tech_name": "Jane C.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.9,
        "customer_name": "Virginia Moreno",
        "service_type": "Garage Door Off Track",
        "tech_name": "Scott C.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.9,
        "customer_name": "Kristopher Harrell",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Michele P.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.9,
        "customer_name": "Sara Graham PhD",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Corey W.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-08",
        "review_rate": 5.0,
        "customer_name": "Sabrina Allen",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Julie W.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-18",
        "review_rate": 5.0,
        "customer_name": "David Paul",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Christian D.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.8,
        "customer_name": "Brent Nunez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Joseph C.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.9,
        "customer_name": "Colton Mcintosh",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Anna J.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-29",
        "review_rate": 5.0,
        "customer_name": "Courtney Rogers",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Troy S.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.8,
        "customer_name": "Heather Craig",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Brittany R.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.9,
        "customer_name": "Erin Anthony",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Luis V.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.9,
        "customer_name": "Phillip Price",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Gerald A.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.9,
        "customer_name": "David Freeman",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Stephanie H.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.9,
        "customer_name": "Richard Foster",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Erica P.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.8,
        "customer_name": "Joshua Mejia",
        "service_type": "Garage Door Installation",
        "tech_name": "Christopher G.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "Melissa Richardson MD",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Daniel B.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.8,
        "customer_name": "James Sullivan",
        "service_type": "Garage Door Services",
        "tech_name": "Samantha K.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-28",
        "review_rate": 5.0,
        "customer_name": "James Cooper",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Richard T.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.9,
        "customer_name": "Sarah Harrison",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Cody B.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.8,
        "customer_name": "Jennifer Turner",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Adam W.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.9,
        "customer_name": "Kyle Benson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Margaret P.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-18",
        "review_rate": 4.8,
        "customer_name": "Andrew Anderson DDS",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jacob B.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-13",
        "review_rate": 5.0,
        "customer_name": "Leroy Bennett",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Michael A.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.9,
        "customer_name": "Robert Schultz",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Christopher G.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.8,
        "customer_name": "Erik Solis",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Paige C.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-12",
        "review_rate": 5.0,
        "customer_name": "Alan Moore Jr.",
        "service_type": "Garage Door Services",
        "tech_name": "Misty S.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.8,
        "customer_name": "Ms. Gabrielle Patterson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Kimberly S.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.9,
        "customer_name": "Corey Aguilar DDS",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Michael D.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.9,
        "customer_name": "Michael Lambert",
        "service_type": "Garage Door Insulation",
        "tech_name": "William C.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.8,
        "customer_name": "Michael Smith",
        "service_type": "Garage Door Installation",
        "tech_name": "Kyle O.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.9,
        "customer_name": "Bradley Harding",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "David M.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.8,
        "customer_name": "Carlos Fox",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Rickey G.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.9,
        "customer_name": "Crystal Johnson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jonathan W.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.8,
        "customer_name": "Sherri Edwards",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Briana M.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.8,
        "customer_name": "Bradley Moore",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Sean R.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.9,
        "customer_name": "Tammy Stewart",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Tara L.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.8,
        "customer_name": "Natasha Brown DDS",
        "service_type": "Garage Door Repair",
        "tech_name": "Samantha C.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-15",
        "review_rate": 5.0,
        "customer_name": "Vanessa Mitchell",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Allison W.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.8,
        "customer_name": "Jennifer Green",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Victoria V.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.9,
        "customer_name": "Carolyn Johnson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Michael P.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-24",
        "review_rate": 5.0,
        "customer_name": "Larry White",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Kristen R.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.9,
        "customer_name": "Vanessa James",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Latoya O.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.9,
        "customer_name": "Diane Thomas",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Edward S.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.8,
        "customer_name": "Amber Green",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Teresa M.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.9,
        "customer_name": "Monica Sparks",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Brandon B.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.9,
        "customer_name": "Tommy Mcfarland",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Sandra R.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-17",
        "review_rate": 5.0,
        "customer_name": "Madison Martinez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Paul M.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.8,
        "customer_name": "Joseph Gonzalez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Angela M.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.8,
        "customer_name": "Melissa Barnes",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Joshua S.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-27",
        "review_rate": 5.0,
        "customer_name": "Vincent Rhodes",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Mark C.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.9,
        "customer_name": "Willie Martinez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Laura C.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.8,
        "customer_name": "Lisa Wang",
        "service_type": "Garage Door Off Track",
        "tech_name": "Michael G.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.9,
        "customer_name": "Russell King",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Lindsey D.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-15",
        "review_rate": 5.0,
        "customer_name": "Stanley Powell",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Raymond T.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-25",
        "review_rate": 5.0,
        "customer_name": "Kristen Sellers",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kristen H.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.9,
        "customer_name": "Kara Ferguson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "James R.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.9,
        "customer_name": "Janice Garcia",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Richard A.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.9,
        "customer_name": "Stephen Moss",
        "service_type": "Garage Door Insulation",
        "tech_name": "Roger D.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.8,
        "customer_name": "Katie Adams",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Andrew G.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.9,
        "customer_name": "Gary Watson",
        "service_type": "Garage Door Installation",
        "tech_name": "Jeffrey G.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.9,
        "customer_name": "Walter George",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Amber G.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.9,
        "customer_name": "Ricardo Valdez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Melissa H.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.9,
        "customer_name": "Dr. Megan Quinn DDS",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Brenda B.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.8,
        "customer_name": "Cameron Harrison",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Lisa M.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.8,
        "customer_name": "Thomas Hines",
        "service_type": "Garage Door Services",
        "tech_name": "Angel L.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.8,
        "customer_name": "Kristina Cruz",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Erin B.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.8,
        "customer_name": "David Hood",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jade W.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.8,
        "customer_name": "Christopher Smith",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Katherine J.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "Paul Thompson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Glen F.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-22",
        "review_rate": 5.0,
        "customer_name": "Nancy Matthews",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Melissa R.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Erik Roy",
        "service_type": "Garage Door Repair",
        "tech_name": "Jason R.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-17",
        "review_rate": 5.0,
        "customer_name": "Mitchell Murray",
        "service_type": "Garage Door Installation",
        "tech_name": "Lydia P.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.9,
        "customer_name": "Mitchell Williams",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Sarah R.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "Justin Phillips",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Philip P.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.8,
        "customer_name": "Roberto Cannon",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Stephen K.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.8,
        "customer_name": "Sara Gould",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jon V.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.9,
        "customer_name": "David Wise",
        "service_type": "Garage Door Off Track",
        "tech_name": "Nathan H.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.8,
        "customer_name": "Yvonne Ramos DDS",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kevin O.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "Chloe Terry",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Lindsey S.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.9,
        "customer_name": "Heather Perry",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Danielle B.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.9,
        "customer_name": "Kevin George",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Larry R.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.8,
        "customer_name": "Katelyn Hughes",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Amanda W.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-28",
        "review_rate": 5.0,
        "customer_name": "Marcus Davis",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "William B.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.8,
        "customer_name": "Alan Gibson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Katherine C.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.9,
        "customer_name": "Robert Cain",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Crystal M.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.9,
        "customer_name": "Alexander Fitzpatrick",
        "service_type": "Garage Door Services",
        "tech_name": "Laura P.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "Margaret Romero",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Pamela B.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-28",
        "review_rate": 5.0,
        "customer_name": "Nancy Dawson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jamie G.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.8,
        "customer_name": "Christopher Hunt",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Cynthia C.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "Kimberly Miles",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Kelly V.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-10",
        "review_rate": 5.0,
        "customer_name": "David Johnson",
        "service_type": "Garage Door Repair",
        "tech_name": "Stephen A.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.8,
        "customer_name": "Marcus Munoz",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Christopher S.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-23",
        "review_rate": 4.8,
        "customer_name": "Douglas Ellis",
        "service_type": "Garage Door Insulation",
        "tech_name": "Heather R.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.8,
        "customer_name": "Mariah Smith",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Michelle O.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.9,
        "customer_name": "Jared Turner",
        "service_type": "Garage Door Off Track",
        "tech_name": "John G.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.9,
        "customer_name": "Taylor Suarez",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Daniel M.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.8,
        "customer_name": "Karla Griffin",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jennifer T.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.8,
        "customer_name": "Mark Anderson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jamie J.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.9,
        "customer_name": "Jonathan Burns",
        "service_type": "Garage Door Installation",
        "tech_name": "Lisa B.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-18",
        "review_rate": 5.0,
        "customer_name": "Sara Roy",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Lisa M.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.9,
        "customer_name": "Heather Weber",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Tonya S.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.9,
        "customer_name": "Tamara Williams",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jasmine M.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.9,
        "customer_name": "Kimberly Powell",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Paul W.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Gay",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "James B.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "Deanna Jones",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Amy D.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.9,
        "customer_name": "Timothy Bass",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Caitlin W.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.8,
        "customer_name": "Edward Davis",
        "service_type": "Garage Door Off Track",
        "tech_name": "Joshua H.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Theresa Schmitt",
        "service_type": "Garage Door Off Track",
        "tech_name": "Angela B.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.9,
        "customer_name": "Kevin Garcia",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Joseph B.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.9,
        "customer_name": "Steven Williams",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Russell M.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.9,
        "customer_name": "Valerie Bowen",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jennifer R.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.9,
        "customer_name": "Christy Taylor",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Sean R.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.9,
        "customer_name": "Michelle Fox",
        "service_type": "Garage Door Insulation",
        "tech_name": "Priscilla R.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.8,
        "customer_name": "Hannah Graham",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Matthew H.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.9,
        "customer_name": "Holly Thompson",
        "service_type": "Garage Door Repair",
        "tech_name": "Lisa T.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.9,
        "customer_name": "Rebecca Barajas",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Karen L.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.9,
        "customer_name": "Sean Mcintyre",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Samantha W.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.8,
        "customer_name": "Denise Silva",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Garrett B.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Matthew Clark",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Stephanie L.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.8,
        "customer_name": "Michael Brown",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Kevin C.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.8,
        "customer_name": "Lisa Baldwin",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "William R.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-09",
        "review_rate": 4.9,
        "customer_name": "Victoria Foster",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Nicole H.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "Justin Tate",
        "service_type": "Garage Door Maintenance",
        "tech_name": "John S.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-06",
        "review_rate": 5.0,
        "customer_name": "Charles Lewis",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Dylan M.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.8,
        "customer_name": "Alexis Avila",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Lisa H.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.8,
        "customer_name": "Brittany Robbins",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Laura C.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Vazquez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Brian R.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-14",
        "review_rate": 5.0,
        "customer_name": "Phillip Rhodes",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Christopher M.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-20",
        "review_rate": 5.0,
        "customer_name": "Lauren Adams",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Richard A.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.9,
        "customer_name": "Mr. Logan Davis",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Matthew B.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-28",
        "review_rate": 4.9,
        "customer_name": "Kelsey Odonnell",
        "service_type": "Garage Door Services",
        "tech_name": "Danielle M.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.9,
        "customer_name": "John Thompson",
        "service_type": "Garage Door Opener",
        "tech_name": "Trevor G.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-31",
        "review_rate": 5.0,
        "customer_name": "Corey Hoffman",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "James R.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.8,
        "customer_name": "Crystal Briggs",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Janice C.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.8,
        "customer_name": "Jason Blanchard",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Sean K.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.8,
        "customer_name": "Makayla Clements",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Suzanne S.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Connie Carter",
        "service_type": "Garage Door Opener",
        "tech_name": "Christopher Y.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.9,
        "customer_name": "Ralph Kim",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jennifer C.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "Hannah Bray",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Maureen M.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-20",
        "review_rate": 5.0,
        "customer_name": "Phillip Harvey",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kirsten M.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-12",
        "review_rate": 5.0,
        "customer_name": "Gary Estes",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Laura B.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "Paige Farrell",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Kenneth S.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.9,
        "customer_name": "James Meyer",
        "service_type": "Garage Door Services",
        "tech_name": "Melissa A.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-27",
        "review_rate": 5.0,
        "customer_name": "Melanie Carson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jasmine T.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-19",
        "review_rate": 5.0,
        "customer_name": "Rickey Gallagher",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Becky W.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-09",
        "review_rate": 5.0,
        "customer_name": "Taylor Walker",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Dorothy M.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-14",
        "review_rate": 5.0,
        "customer_name": "Kim Vincent",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Mckenzie M.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-30",
        "review_rate": 5.0,
        "customer_name": "Jeremy Goodman",
        "service_type": "Garage Door Services",
        "tech_name": "Kristie M.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.9,
        "customer_name": "Joanna Jones",
        "service_type": "Garage Door Installation",
        "tech_name": "Brandon H.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-06",
        "review_rate": 5.0,
        "customer_name": "Craig Wong",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jeffrey J.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-27",
        "review_rate": 5.0,
        "customer_name": "Laura Davis",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Tara R.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.8,
        "customer_name": "Darrell Smith",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Christina G.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.8,
        "customer_name": "Jeremy Garcia",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Helen F.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.9,
        "customer_name": "Jason Watson",
        "service_type": "Garage Door Services",
        "tech_name": "Matthew J.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.8,
        "customer_name": "James Johnson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Andrew H.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.8,
        "customer_name": "Nancy Wood",
        "service_type": "Garage Door Off Track",
        "tech_name": "Paul E.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-15",
        "review_rate": 5.0,
        "customer_name": "Melinda Jackson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Taylor H.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "Jeanette Wilson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Judy V.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.9,
        "customer_name": "Amy Jones",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Bryan T.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.9,
        "customer_name": "Sara Ponce",
        "service_type": "Garage Door Repair",
        "tech_name": "Tara W.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-06",
        "review_rate": 5.0,
        "customer_name": "Mary Coleman",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Amanda C.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-02",
        "review_rate": 5.0,
        "customer_name": "Christopher Day",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Tina H.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-27",
        "review_rate": 5.0,
        "customer_name": "Ethan Long",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kim R.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.9,
        "customer_name": "Jacqueline Clay",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Alejandro C.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.8,
        "customer_name": "Kendra Walker",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Janice O.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.9,
        "customer_name": "Adrian Barrera",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Nathan H.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.8,
        "customer_name": "Natalie Taylor",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Karen W.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.9,
        "customer_name": "Alicia Moore",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Andrew H.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-17",
        "review_rate": 5.0,
        "customer_name": "Vanessa Price",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Curtis S.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.9,
        "customer_name": "Adrian Wise",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jason G.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.9,
        "customer_name": "Karen Conner",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jerry B.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.9,
        "customer_name": "Kevin Cunningham",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Mark S.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.9,
        "customer_name": "Randy Stephens",
        "service_type": "Garage Door Installation",
        "tech_name": "Daniel B.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.8,
        "customer_name": "Michael Long",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Stephen R.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.9,
        "customer_name": "Richard Floyd",
        "service_type": "Garage Door Installation",
        "tech_name": "Mathew C.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.9,
        "customer_name": "Troy Barton",
        "service_type": "Garage Door Installation",
        "tech_name": "Steven B.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.9,
        "customer_name": "Alex Hart",
        "service_type": "Garage Door Services",
        "tech_name": "Thomas R.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.8,
        "customer_name": "Zoe Odom",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Stephanie C.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.9,
        "customer_name": "Carla Sosa",
        "service_type": "Garage Door Installation",
        "tech_name": "Heidi M.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.9,
        "customer_name": "Shelby Payne",
        "service_type": "Garage Door Repair",
        "tech_name": "Cheryl H.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.9,
        "customer_name": "Matthew Ryan",
        "service_type": "Garage Door Insulation",
        "tech_name": "Andrea H.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.8,
        "customer_name": "Veronica Torres",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Renee J.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-07",
        "review_rate": 5.0,
        "customer_name": "Matthew Leblanc",
        "service_type": "Garage Door Off Track",
        "tech_name": "Laura P.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.8,
        "customer_name": "Jane Miller",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jessica G.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.9,
        "customer_name": "Diana Mcclure",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Stephanie C.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.8,
        "customer_name": "Karen Hernandez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Curtis E.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "Eric Miller",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Kristen M.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.9,
        "customer_name": "Anthony Jones",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Tara R.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.9,
        "customer_name": "Mark Allen",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Carl N.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.9,
        "customer_name": "Alicia Miller",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Angela T.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-20",
        "review_rate": 5.0,
        "customer_name": "Kelly Greene",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jeffrey M.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.9,
        "customer_name": "Gregory Powell",
        "service_type": "Garage Door Services",
        "tech_name": "Scott A.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.8,
        "customer_name": "Leslie Montoya",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Alejandro E.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.9,
        "customer_name": "Rebekah Mathis",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Charles S.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-04",
        "review_rate": 5.0,
        "customer_name": "Keith Li",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Larry N.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.9,
        "customer_name": "Julie Wright",
        "service_type": "Garage Door Opener",
        "tech_name": "Sean K.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-13",
        "review_rate": 5.0,
        "customer_name": "Chelsea White",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jasmine F.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.9,
        "customer_name": "Wanda Jenkins",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Daniel L.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.9,
        "customer_name": "Kimberly Ramirez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Donna R.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.9,
        "customer_name": "Sandra Martinez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Dakota C.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.8,
        "customer_name": "Madison Miller",
        "service_type": "Garage Door Installation",
        "tech_name": "Jessica N.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-21",
        "review_rate": 5.0,
        "customer_name": "Thomas Brown",
        "service_type": "Garage Door Insulation",
        "tech_name": "Brian L.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.9,
        "customer_name": "Marisa Myers",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Paul E.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.8,
        "customer_name": "Dennis May",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Tracy S.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.9,
        "customer_name": "Stephanie Herrera",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Logan H.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.9,
        "customer_name": "Jon Price",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Holly R.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.8,
        "customer_name": "Nicole Herrera",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Mary B.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.8,
        "customer_name": "Claudia Andrews",
        "service_type": "Garage Door Track Repair",
        "tech_name": "John J.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Alexander Webb",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kevin H.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-17",
        "review_rate": 5.0,
        "customer_name": "Russell Reynolds",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jessica W.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.8,
        "customer_name": "Geoffrey Rivera",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Richard B.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.8,
        "customer_name": "Jermaine Meyers",
        "service_type": "Garage Door Installation",
        "tech_name": "Carmen D.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.8,
        "customer_name": "Lisa Kelly",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jason G.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.9,
        "customer_name": "Stacy Elliott PhD",
        "service_type": "Garage Door Off Track",
        "tech_name": "Lindsey S.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.8,
        "customer_name": "Edgar Griffith",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Laurie G.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-18",
        "review_rate": 5.0,
        "customer_name": "Daniel Cole",
        "service_type": "Garage Door Off Track",
        "tech_name": "Carlos H.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-11",
        "review_rate": 5.0,
        "customer_name": "Lisa Garcia",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "David G.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.9,
        "customer_name": "Carla Reilly",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Mary D.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.9,
        "customer_name": "Deborah Castillo",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Francis M.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.8,
        "customer_name": "Jennifer Hughes",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Ashley J.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.8,
        "customer_name": "Susan Williams",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Sierra Y.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.8,
        "customer_name": "David Vincent",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Madeline S.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.9,
        "customer_name": "Keith Potter",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Rebecca G.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-07",
        "review_rate": 4.9,
        "customer_name": "Joseph Bauer",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Sonya C.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.8,
        "customer_name": "Robin Dyer",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Terri S.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.8,
        "customer_name": "Jennifer Sanders",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Sarah C.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.9,
        "customer_name": "Matthew Juarez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Teresa W.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.9,
        "customer_name": "Edward Black DVM",
        "service_type": "Garage Door Track Repair",
        "tech_name": "David C.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.9,
        "customer_name": "Erika Pearson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Dennis R.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-22",
        "review_rate": 5.0,
        "customer_name": "Linda Wilson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Melanie C.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "Ann Dean",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Erik C.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.9,
        "customer_name": "Paul Ali",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Louis K.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.9,
        "customer_name": "Emily Cannon",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Andrea L.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.8,
        "customer_name": "Sandra Horton",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Brady H.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.8,
        "customer_name": "Melissa Castaneda",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Troy H.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-20",
        "review_rate": 5.0,
        "customer_name": "Antonio Edwards",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Lori C.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-20",
        "review_rate": 5.0,
        "customer_name": "Tony Cooper",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Donald G.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-16",
        "review_rate": 5.0,
        "customer_name": "Mary Hayes",
        "service_type": "Garage Door Opener",
        "tech_name": "Eric V.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.9,
        "customer_name": "Brittany Davis",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Anna P.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.8,
        "customer_name": "Kevin Alvarez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Kevin H.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.8,
        "customer_name": "Amber Brennan",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kristin M.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.9,
        "customer_name": "Michael Roberts",
        "service_type": "Garage Door Insulation",
        "tech_name": "Debbie C.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.9,
        "customer_name": "Stacey Roberson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Brad C.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.9,
        "customer_name": "Marcus Kemp",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Grant C.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.8,
        "customer_name": "Rick Bush",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Rose E.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.9,
        "customer_name": "Frank Wu",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Beth C.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.8,
        "customer_name": "Jeanette Clark",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Roger W.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.9,
        "customer_name": "Christina Anderson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Robin W.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.9,
        "customer_name": "Heather Blackwell",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Angela J.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.8,
        "customer_name": "Chris Watts",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Daniel P.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-22",
        "review_rate": 5.0,
        "customer_name": "Thomas Winters",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Samantha W.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.9,
        "customer_name": "Barbara Thomas",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Samantha M.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.9,
        "customer_name": "David Miles",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kathryn H.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-10",
        "review_rate": 5.0,
        "customer_name": "Ashley Woods",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Devin M.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.8,
        "customer_name": "Kathryn Sawyer",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Cheryl W.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.9,
        "customer_name": "Theresa Sanchez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Steven B.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.9,
        "customer_name": "Jessica Martin",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Kimberly E.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-01",
        "review_rate": 4.9,
        "customer_name": "Kayla Walls",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "David M.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.9,
        "customer_name": "Tony Oliver",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Steven C.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-18",
        "review_rate": 4.9,
        "customer_name": "Melissa Stuart",
        "service_type": "Garage Door Services",
        "tech_name": "Carl C.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.9,
        "customer_name": "James Hall",
        "service_type": "Garage Door Maintenance",
        "tech_name": "James G.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.9,
        "customer_name": "Laura Allen",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Felicia S.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-22",
        "review_rate": 5.0,
        "customer_name": "Ethan Bernard",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Karen V.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Heather Rogers",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Robert D.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.9,
        "customer_name": "Brian Miller",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Kenneth V.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.9,
        "customer_name": "Daniel Ford",
        "service_type": "Garage Door Repair",
        "tech_name": "Christian H.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-23",
        "review_rate": 5.0,
        "customer_name": "Samantha Alvarado",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Robert H.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.9,
        "customer_name": "Brian Morris",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Karen C.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.8,
        "customer_name": "John Cox",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kathryn S.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.9,
        "customer_name": "Hector Dunlap",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jessica Y.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.8,
        "customer_name": "David Hall",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Elizabeth T.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.9,
        "customer_name": "Kathy Roberts",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Lauren C.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-01",
        "review_rate": 5.0,
        "customer_name": "Anne Davidson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kristen J.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.9,
        "customer_name": "Edward Andrews",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Ashlee A.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.9,
        "customer_name": "Jonathon Ayers",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Dennis H.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.9,
        "customer_name": "Joseph Barrett",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jessica W.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.9,
        "customer_name": "Jeffery Walker",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Maria W.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.8,
        "customer_name": "Melanie Schaefer",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kathleen C.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "William Robinson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Tabitha F.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-05",
        "review_rate": 5.0,
        "customer_name": "Amanda Davis",
        "service_type": "Garage Door Services",
        "tech_name": "Matthew M.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-15",
        "review_rate": 5.0,
        "customer_name": "Jonathan Taylor",
        "service_type": "Garage Door Insulation",
        "tech_name": "Michelle G.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.8,
        "customer_name": "Ryan Nelson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Glenn C.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Jared Cooley",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "John R.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.8,
        "customer_name": "Zachary Smith",
        "service_type": "Garage Door Opener",
        "tech_name": "Tina A.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.8,
        "customer_name": "Tyler Jensen",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Nicholas R.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-14",
        "review_rate": 5.0,
        "customer_name": "Jeffery Patel",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Theresa B.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-19",
        "review_rate": 5.0,
        "customer_name": "Karen Williams",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Lindsey J.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.9,
        "customer_name": "Micheal Phillips",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Lauren H.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.9,
        "customer_name": "Taylor Griffin MD",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Aaron D.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.8,
        "customer_name": "Scott Parsons",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Emily P.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-21",
        "review_rate": 5.0,
        "customer_name": "Maria Powell",
        "service_type": "Garage Door Repair",
        "tech_name": "Shelby J.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.9,
        "customer_name": "Lindsey Wilkins",
        "service_type": "Garage Door Services",
        "tech_name": "Deanna J.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-09",
        "review_rate": 4.8,
        "customer_name": "Melissa Watson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jay L.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.8,
        "customer_name": "Jason Hughes",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Craig R.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-27",
        "review_rate": 5.0,
        "customer_name": "Crystal Williams",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Travis S.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.9,
        "customer_name": "Angela Carlson",
        "service_type": "Garage Door Installation",
        "tech_name": "Sarah P.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Mary Rodriguez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Karen E.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.9,
        "customer_name": "Nancy Smith",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Christina S.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-05",
        "review_rate": 5.0,
        "customer_name": "Andrew Baker",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Ariana K.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.8,
        "customer_name": "Vanessa Washington",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Melanie S.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.9,
        "customer_name": "Hannah Carroll",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Heather S.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.8,
        "customer_name": "Kristin Lloyd",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "William C.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.9,
        "customer_name": "Breanna Davis",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Elizabeth C.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-11",
        "review_rate": 5.0,
        "customer_name": "Ronald Scott",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Judy J.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.8,
        "customer_name": "Anna Green",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Kimberly F.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.9,
        "customer_name": "Michelle Reid",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Michael M.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.8,
        "customer_name": "Brandon Allen",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Hector L.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.8,
        "customer_name": "Mr. Jason English MD",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Ryan S.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.8,
        "customer_name": "Jennifer Fowler",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kevin S.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-30",
        "review_rate": 5.0,
        "customer_name": "Joyce Flores",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Mark S.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-18",
        "review_rate": 4.9,
        "customer_name": "Henry Myers",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Benjamin N.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.9,
        "customer_name": "Maria Walters",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Chad L.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.9,
        "customer_name": "Amanda Mendoza",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Eugene L.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-17",
        "review_rate": 5.0,
        "customer_name": "Chris Huff",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Sharon W.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-31",
        "review_rate": 5.0,
        "customer_name": "Cassie Morse",
        "service_type": "Garage Door Installation",
        "tech_name": "Jason L.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-20",
        "review_rate": 5.0,
        "customer_name": "Marie Adams",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Caitlyn B.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.8,
        "customer_name": "Bonnie Porter",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Michelle M.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.8,
        "customer_name": "David Curry",
        "service_type": "Garage Door Opener",
        "tech_name": "Ashley F.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.8,
        "customer_name": "Cynthia Foster",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Robert O.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.9,
        "customer_name": "Blake Wallace",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Christina M.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.8,
        "customer_name": "Linda Castillo",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Susan T.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.9,
        "customer_name": "Collin Mcclure",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Frank M.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Morris",
        "service_type": "Garage Door Services",
        "tech_name": "Emily B.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "Alvin Ayala",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Alexander P.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.9,
        "customer_name": "Nancy Brown",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Stephanie J.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.8,
        "customer_name": "Douglas Rojas",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Rebecca C.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.9,
        "customer_name": "David Russell",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Sheila M.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.8,
        "customer_name": "David Brown",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Briana G.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-13",
        "review_rate": 5.0,
        "customer_name": "Christopher Olson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Barbara B.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.9,
        "customer_name": "Dr. Justin Blackwell",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Monica F.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.9,
        "customer_name": "Ian Barnett",
        "service_type": "Garage Door Installation",
        "tech_name": "Steven C.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.9,
        "customer_name": "George Douglas",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Bryan W.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.9,
        "customer_name": "Kenneth Dominguez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Amanda H.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-16",
        "review_rate": 5.0,
        "customer_name": "Kimberly Wood",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Sara C.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-20",
        "review_rate": 5.0,
        "customer_name": "Tina Stewart",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Christopher Z.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-18",
        "review_rate": 4.9,
        "customer_name": "Philip Johnson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Robert Y.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.8,
        "customer_name": "Robert Anderson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Paul P.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.9,
        "customer_name": "Brittney Pittman",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Steven B.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.8,
        "customer_name": "Edward Baker",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Kevin P.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.9,
        "customer_name": "Stephen Jackson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jeremy W.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.9,
        "customer_name": "Mark Young",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Amy P.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.9,
        "customer_name": "Eric Snyder",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Breanna C.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-06",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Best",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Duane T.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.9,
        "customer_name": "Lance Rodriguez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Joseph G.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-11",
        "review_rate": 5.0,
        "customer_name": "Rhonda Meyer",
        "service_type": "Garage Door Repair",
        "tech_name": "Antonio J.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.9,
        "customer_name": "Jennifer Espinoza",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Willie C.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.9,
        "customer_name": "Debra Chase",
        "service_type": "Garage Door Insulation",
        "tech_name": "Alyssa C.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.9,
        "customer_name": "Judy Ross",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Scott R.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-31",
        "review_rate": 5.0,
        "customer_name": "April Hamilton",
        "service_type": "Garage Door Installation",
        "tech_name": "Amanda C.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "James Boyle",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Eric I.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.8,
        "customer_name": "Justin Miles",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Mindy D.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-23",
        "review_rate": 5.0,
        "customer_name": "Wyatt Wood",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Kathryn H.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.8,
        "customer_name": "Amy Hopkins",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jennifer S.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-28",
        "review_rate": 5.0,
        "customer_name": "Jesse Brown",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Erika E.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.9,
        "customer_name": "Angel Stone",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jeremy H.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.8,
        "customer_name": "James Conner",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Daniel F.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.8,
        "customer_name": "Joy Gonzalez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Zachary M.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.9,
        "customer_name": "Tammy Martinez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Daisy K.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.8,
        "customer_name": "William Black",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "John S.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.9,
        "customer_name": "Dr. Dwayne Caldwell",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Melissa M.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-11",
        "review_rate": 5.0,
        "customer_name": "Jordan Taylor",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Derek M.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-28",
        "review_rate": 4.8,
        "customer_name": "Curtis Fletcher",
        "service_type": "Garage Door Opener",
        "tech_name": "Meghan C.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.9,
        "customer_name": "Russell Diaz",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Nancy H.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-30",
        "review_rate": 5.0,
        "customer_name": "Kevin Ramos",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Zachary B.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.9,
        "customer_name": "Ryan Sawyer",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Megan S.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-15",
        "review_rate": 5.0,
        "customer_name": "Diane Jacobs",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Brian S.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.8,
        "customer_name": "Christian Meyer",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Gloria M.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.9,
        "customer_name": "Anthony Bishop",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Patricia J.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.9,
        "customer_name": "Ashley Hopkins",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Ashley K.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-24",
        "review_rate": 5.0,
        "customer_name": "Michael Price",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Curtis M.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.9,
        "customer_name": "Jenna Morales",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Christopher T.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.9,
        "customer_name": "Emily Williams",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jeffrey T.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.9,
        "customer_name": "Peter Barker",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "John A.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.9,
        "customer_name": "Dr. Jason Mckenzie",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Christopher J.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.9,
        "customer_name": "Austin York",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Christopher E.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.8,
        "customer_name": "Tiffany Allen",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Samantha S.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-19",
        "review_rate": 5.0,
        "customer_name": "Sabrina Adkins",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Brian J.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.9,
        "customer_name": "Ellen Long",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Troy T.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-25",
        "review_rate": 5.0,
        "customer_name": "Claudia Reyes",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Zachary C.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.8,
        "customer_name": "Melissa Gentry",
        "service_type": "Garage Door Installation",
        "tech_name": "Miguel R.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-09",
        "review_rate": 5.0,
        "customer_name": "Colleen Smith",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Caroline R.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.9,
        "customer_name": "Jennifer Houston",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Angela B.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-30",
        "review_rate": 4.9,
        "customer_name": "Tiffany Henson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jeffrey H.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.8,
        "customer_name": "Patrick Harris",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Christopher R.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.9,
        "customer_name": "Dr. Maxwell Powell",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Amber W.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.8,
        "customer_name": "Jeffrey Meyer",
        "service_type": "Garage Door Insulation",
        "tech_name": "Kimberly W.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.9,
        "customer_name": "Stacy Hernandez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jerry F.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.9,
        "customer_name": "Michael Long",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Johnny D.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.9,
        "customer_name": "Richard Green",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Tamara G.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-29",
        "review_rate": 5.0,
        "customer_name": "Timothy Rasmussen",
        "service_type": "Garage Door Installation",
        "tech_name": "Kristin C.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.9,
        "customer_name": "Anthony Andrade",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Barbara R.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-09",
        "review_rate": 5.0,
        "customer_name": "Edward Fisher",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Kristin B.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.8,
        "customer_name": "Dr. Andrea Thompson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jon S.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-14",
        "review_rate": 5.0,
        "customer_name": "Kaitlyn Compton",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Cynthia D.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.9,
        "customer_name": "Scott Martinez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jennifer W.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.9,
        "customer_name": "Jesus Mccullough",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Andrew A.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Kaitlyn Jackson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Thomas P.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-18",
        "review_rate": 5.0,
        "customer_name": "Debbie Manning",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Drew G.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.8,
        "customer_name": "Curtis Smith",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Gabrielle N.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.9,
        "customer_name": "Shelly Pierce",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Heather S.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-05",
        "review_rate": 5.0,
        "customer_name": "Kari Martin",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Matthew R.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-15",
        "review_rate": 5.0,
        "customer_name": "Shirley Santos",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jonathan S.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-17",
        "review_rate": 5.0,
        "customer_name": "Cynthia Garrett",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jessica W.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.9,
        "customer_name": "Raymond Robinson",
        "service_type": "Garage Door Installation",
        "tech_name": "Tanya S.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-14",
        "review_rate": 5.0,
        "customer_name": "Dawn Shannon",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Caleb L.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.9,
        "customer_name": "Kevin Murray",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Justin G.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-08",
        "review_rate": 5.0,
        "customer_name": "Vanessa Roberts",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Daniel M.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-15",
        "review_rate": 5.0,
        "customer_name": "Sarah Jordan",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Monica M.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.8,
        "customer_name": "Jennifer Vance",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kathy P.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.9,
        "customer_name": "Richard Vasquez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Eric P.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.9,
        "customer_name": "Curtis Hernandez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Arthur F.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.9,
        "customer_name": "Karen Davis",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Maria P.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.8,
        "customer_name": "Mary Ware",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Amy R.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.9,
        "customer_name": "Crystal Williamson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Brandon G.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.9,
        "customer_name": "Jose Garcia",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Tammy B.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.9,
        "customer_name": "Thomas Thompson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Chelsea P.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-26",
        "review_rate": 5.0,
        "customer_name": "Kimberly Zhang",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Tiffany O.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-13",
        "review_rate": 5.0,
        "customer_name": "Adam Harper",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Trevor C.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.8,
        "customer_name": "Natalie Rivera",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Stephanie G.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.9,
        "customer_name": "Brittany Carey",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jeffrey M.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "David Mccormick",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Shelly C.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-31",
        "review_rate": 5.0,
        "customer_name": "Leslie Mccullough",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Sarah D.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.8,
        "customer_name": "David Robbins",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Richard D.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.9,
        "customer_name": "Michaela Martinez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Erin B.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.9,
        "customer_name": "Amber Smith",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Rodney C.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Paula Higgins",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Donna C.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.9,
        "customer_name": "Tina Flores",
        "service_type": "Garage Door Insulation",
        "tech_name": "Sean C.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.9,
        "customer_name": "Carl Cook",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Kevin J.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.9,
        "customer_name": "Keith Nichols",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Ashley A.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-09",
        "review_rate": 5.0,
        "customer_name": "Robert Wilson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Lynn K.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "Grace Hoffman",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jennifer T.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-08",
        "review_rate": 5.0,
        "customer_name": "David Brown",
        "service_type": "Garage Door Off Track",
        "tech_name": "Gabriella R.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.8,
        "customer_name": "Kenneth Gallagher",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Michelle E.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.9,
        "customer_name": "Ruth Murphy",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Shane B.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.8,
        "customer_name": "Robert Clements",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Stephanie M.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.8,
        "customer_name": "Anthony Turner",
        "service_type": "Garage Door Repair",
        "tech_name": "Brittany T.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.8,
        "customer_name": "Nancy Garcia",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Kim G.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.8,
        "customer_name": "Christopher Peters",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Bryan C.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-21",
        "review_rate": 5.0,
        "customer_name": "Jacob Kelly",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Amanda R.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.9,
        "customer_name": "Katherine Holloway",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Rebecca B.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "Emily Stewart",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Andres C.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.8,
        "customer_name": "Lindsay Wolf",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Lauren A.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-25",
        "review_rate": 5.0,
        "customer_name": "Kristen Jones",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jennifer S.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-02",
        "review_rate": 5.0,
        "customer_name": "Kelsey White",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Stephanie L.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-04",
        "review_rate": 5.0,
        "customer_name": "Mathew Johnson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Brian M.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.9,
        "customer_name": "Angela Johnson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Caleb S.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-13",
        "review_rate": 5.0,
        "customer_name": "Monica Smith",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Alexis G.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-14",
        "review_rate": 5.0,
        "customer_name": "David Zamora",
        "service_type": "Garage Door Repair",
        "tech_name": "Andrea R.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.9,
        "customer_name": "Tonya Wolf",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Clifford C.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.8,
        "customer_name": "Jason Booth",
        "service_type": "Garage Door Insulation",
        "tech_name": "Erika V.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.8,
        "customer_name": "Jerry Davis",
        "service_type": "Garage Door Off Track",
        "tech_name": "Melissa A.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.9,
        "customer_name": "Shawn Obrien",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Debra B.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.9,
        "customer_name": "Nicole Todd",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Terri R.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.8,
        "customer_name": "Michael Lawson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Benjamin S.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.8,
        "customer_name": "Thomas Craig",
        "service_type": "Garage Door Services",
        "tech_name": "Jorge N.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.8,
        "customer_name": "Sarah Herrera",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Teresa M.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.9,
        "customer_name": "Lacey Hansen",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Shelby M.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-15",
        "review_rate": 5.0,
        "customer_name": "Walter Phillips",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Melissa S.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Carol Smith",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Donald W.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.9,
        "customer_name": "William Anderson",
        "service_type": "Garage Door Repair",
        "tech_name": "Jasmine R.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.9,
        "customer_name": "Lisa Olson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Donald W.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.8,
        "customer_name": "Richard Tanner",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Amanda D.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-18",
        "review_rate": 5.0,
        "customer_name": "Alyssa Larson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Regina C.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.8,
        "customer_name": "Patrick Thomas",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "John W.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-09",
        "review_rate": 5.0,
        "customer_name": "Lindsay Gallagher",
        "service_type": "Garage Door Track Repair",
        "tech_name": "John U.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-06",
        "review_rate": 5.0,
        "customer_name": "Nicole Snyder",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "James R.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.9,
        "customer_name": "Brandon Cole",
        "service_type": "Garage Door Insulation",
        "tech_name": "Christine N.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-23",
        "review_rate": 5.0,
        "customer_name": "Mary Rogers",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Sean L.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.9,
        "customer_name": "Peggy Carter",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Adam A.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.8,
        "customer_name": "Susan Thomas",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Cheryl B.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-30",
        "review_rate": 4.9,
        "customer_name": "Margaret Richardson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jessica K.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.9,
        "customer_name": "Linda Choi",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Maria S.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-28",
        "review_rate": 4.9,
        "customer_name": "Jesse Ballard",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Randy A.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.8,
        "customer_name": "Heather Jordan",
        "service_type": "Garage Door Installation",
        "tech_name": "Brittany L.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.9,
        "customer_name": "Ronald Jenkins",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Alexa W.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.8,
        "customer_name": "Angela Mccullough",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Emily W.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.9,
        "customer_name": "Kathryn Myers",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Alexis W.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-01",
        "review_rate": 5.0,
        "customer_name": "Darryl Arnold",
        "service_type": "Garage Door Off Track",
        "tech_name": "Stephanie E.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.9,
        "customer_name": "Chelsea Bowen",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Ricky K.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.9,
        "customer_name": "Kurt Solomon",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Andrea G.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.9,
        "customer_name": "Anita Livingston",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Caitlyn L.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.8,
        "customer_name": "John Smith",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jessica H.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.9,
        "customer_name": "Kathleen Johnston",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Alan S.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.9,
        "customer_name": "Kimberly Walker",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Brian G.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.8,
        "customer_name": "Mark Aguirre",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Amber T.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "Mr. Anthony Lee",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Stacy W.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.8,
        "customer_name": "Matthew Kennedy",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jeffrey G.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.9,
        "customer_name": "Catherine Barton",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Christopher G.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Brett Young",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Troy R.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.9,
        "customer_name": "Cindy Hanson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Melody M.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.9,
        "customer_name": "Ryan Morris",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Sarah G.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.9,
        "customer_name": "April Bailey MD",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kaitlyn W.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-13",
        "review_rate": 5.0,
        "customer_name": "Melanie Kennedy",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jay F.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-14",
        "review_rate": 5.0,
        "customer_name": "Michael Sullivan",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Tiffany C.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.9,
        "customer_name": "Hannah Christensen",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Joseph C.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.8,
        "customer_name": "Nicole Allison",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jenna R.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Zachary Taylor",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Bryan B.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-27",
        "review_rate": 5.0,
        "customer_name": "Monica Cortez",
        "service_type": "Garage Door Installation",
        "tech_name": "Dana H.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.8,
        "customer_name": "Chase Thompson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Randy N.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.9,
        "customer_name": "David Miller",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Frank M.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.9,
        "customer_name": "Daniel Myers",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Catherine S.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.8,
        "customer_name": "Brian Curry",
        "service_type": "Garage Door Repair",
        "tech_name": "Anna F.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.8,
        "customer_name": "Christopher Gray",
        "service_type": "Garage Door Off Track",
        "tech_name": "Alexander S.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.8,
        "customer_name": "Tyler Campbell",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Julian J.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-07",
        "review_rate": 5.0,
        "customer_name": "Heather Waters",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Teresa W.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.9,
        "customer_name": "Jennifer Gamble",
        "service_type": "Garage Door Installation",
        "tech_name": "Steven O.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.9,
        "customer_name": "Jennifer Fitzgerald DDS",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Nancy T.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.8,
        "customer_name": "Kari Nielsen",
        "service_type": "Garage Door Services",
        "tech_name": "Heather S.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.9,
        "customer_name": "Marcus Hughes",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Anna J.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.9,
        "customer_name": "Janice Lee",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Robert D.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.9,
        "customer_name": "Kimberly Simmons",
        "service_type": "Garage Door Off Track",
        "tech_name": "Nancy S.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.9,
        "customer_name": "Steven Gonzalez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Beth J.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.8,
        "customer_name": "Alexander Allen",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "John N.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.8,
        "customer_name": "Ronald Kelley",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Joseph R.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-26",
        "review_rate": 5.0,
        "customer_name": "Kellie Clark",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Brenda M.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.8,
        "customer_name": "George Brown",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Michelle B.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-18",
        "review_rate": 5.0,
        "customer_name": "Thomas Gutierrez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Alexander V.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.9,
        "customer_name": "Brandon Cannon",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Sylvia S.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.9,
        "customer_name": "Charles Soto",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Megan H.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.9,
        "customer_name": "Nicole Hayes",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Michael M.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.9,
        "customer_name": "James Munoz",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Paul S.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.9,
        "customer_name": "Johnathan Benitez",
        "service_type": "Garage Door Installation",
        "tech_name": "Andrew R.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-30",
        "review_rate": 4.9,
        "customer_name": "Kenneth Clark",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Diane C.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.8,
        "customer_name": "Mr. Adam Davis",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Aaron A.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.9,
        "customer_name": "Nancy Sellers",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Misty C.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-20",
        "review_rate": 5.0,
        "customer_name": "Lisa Baker",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Teresa R.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.9,
        "customer_name": "Victoria Kennedy",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Christine F.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.8,
        "customer_name": "William Ellis",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jennifer M.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.9,
        "customer_name": "Melanie Martin",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Daniel L.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.8,
        "customer_name": "Steven Cruz",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Krystal P.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.9,
        "customer_name": "Danielle Grant",
        "service_type": "Garage Door Repair",
        "tech_name": "Tara K.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-14",
        "review_rate": 5.0,
        "customer_name": "Raymond Miller",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Todd T.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "Jeremy Wagner",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Eric C.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.9,
        "customer_name": "Dylan Mclaughlin",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Cassidy J.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.8,
        "customer_name": "Laura Chen",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Carlos H.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.9,
        "customer_name": "Diamond Howard",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Tyler W.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.9,
        "customer_name": "Mrs. Sharon Wheeler",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Robert C.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Jennifer Nguyen",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Cameron B.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.9,
        "customer_name": "Cynthia Wang",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Andrew W.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.9,
        "customer_name": "Carla Francis",
        "service_type": "Garage Door Services",
        "tech_name": "William G.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.9,
        "customer_name": "Kenneth Carlson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Tina B.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.9,
        "customer_name": "Mr. George Maddox",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Madison S.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.8,
        "customer_name": "Michael Miller",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Nicole R.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.8,
        "customer_name": "Roberto Warner",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Andrea C.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.9,
        "customer_name": "Eric Allen",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Julian W.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.9,
        "customer_name": "Charles Fox",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Paul R.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.9,
        "customer_name": "Debra Keith",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Anthony P.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.9,
        "customer_name": "Michael Sutton",
        "service_type": "Garage Door Opener",
        "tech_name": "Stephanie C.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.9,
        "customer_name": "Alexander Hinton",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Patricia K.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-24",
        "review_rate": 5.0,
        "customer_name": "Amy Alexander",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Amy G.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-29",
        "review_rate": 5.0,
        "customer_name": "Ronald Nichols",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Bruce S.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.9,
        "customer_name": "Hayley Alvarez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Cody V.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.8,
        "customer_name": "William Gibson Jr.",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Shawn M.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.9,
        "customer_name": "Steven Richard",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Brandon S.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.9,
        "customer_name": "Jeremy Brown",
        "service_type": "Garage Door Repair",
        "tech_name": "Sheila P.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.8,
        "customer_name": "Tammie Pearson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Heather R.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.8,
        "customer_name": "Barbara Smith",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "David M.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-17",
        "review_rate": 5.0,
        "customer_name": "Amy Chan",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Andrew G.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.8,
        "customer_name": "Larry Nelson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Ashley H.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Brittany White PhD",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Briana E.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.8,
        "customer_name": "Chad Wright",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kristin P.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.9,
        "customer_name": "Dr. Douglas Brooks III",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jessica R.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.9,
        "customer_name": "John Young",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Michael R.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Anna Thomas",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Lee K.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "Daniel May",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Natalie H.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.8,
        "customer_name": "Stephanie Murphy",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "William G.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-16",
        "review_rate": 5.0,
        "customer_name": "Patrick Harvey",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kristin B.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "Daniel Crawford",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jesse W.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.8,
        "customer_name": "Brandon Reynolds",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Beverly M.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-12",
        "review_rate": 5.0,
        "customer_name": "Rachel Stevens",
        "service_type": "Garage Door Insulation",
        "tech_name": "Shelly H.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.9,
        "customer_name": "Dr. Sandra Martinez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Ronald C.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-15",
        "review_rate": 5.0,
        "customer_name": "Susan Ellis",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Vincent F.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.8,
        "customer_name": "Dawn Anderson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Michelle C.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.9,
        "customer_name": "Carl Smith",
        "service_type": "Garage Door Repair",
        "tech_name": "Martha S.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-12",
        "review_rate": 5.0,
        "customer_name": "Ryan Jones",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Henry B.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Lisa Jordan",
        "service_type": "Garage Door Opener",
        "tech_name": "Lisa M.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.9,
        "customer_name": "Jeffery Mcbride",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jay G.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.9,
        "customer_name": "Courtney Sandoval",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Joan H.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.9,
        "customer_name": "Nicole Singh",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kevin H.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.8,
        "customer_name": "Gary Marsh",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Walter N.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.9,
        "customer_name": "Daniel Archer",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Gabriel J.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.9,
        "customer_name": "Amy Molina",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Heather W.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.9,
        "customer_name": "Bradley Hutchinson",
        "service_type": "Garage Door Services",
        "tech_name": "Nicole P.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.9,
        "customer_name": "Aaron Moreno",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jane S.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.8,
        "customer_name": "Jason Benson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Megan G.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-20",
        "review_rate": 5.0,
        "customer_name": "Steven Martinez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Scott J.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-18",
        "review_rate": 4.9,
        "customer_name": "Noah Brown",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Sheri M.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.8,
        "customer_name": "Adam Nichols",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Taylor B.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.9,
        "customer_name": "Makayla Simmons",
        "service_type": "Garage Door Insulation",
        "tech_name": "Brandon W.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-31",
        "review_rate": 5.0,
        "customer_name": "Mrs. Lynn Santiago",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Tara S.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.9,
        "customer_name": "David Green",
        "service_type": "Garage Door Repair",
        "tech_name": "Brianna G.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.9,
        "customer_name": "Linda Miller",
        "service_type": "Garage Door Off Track",
        "tech_name": "Courtney H.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.9,
        "customer_name": "Kevin Salas",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Kiara L.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.8,
        "customer_name": "Richard Thompson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Mary R.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-10",
        "review_rate": 5.0,
        "customer_name": "Brandon Salas",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Cory B.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.9,
        "customer_name": "Nicole Taylor",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Kevin S.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.9,
        "customer_name": "Debra Ramos",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jeremy T.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.9,
        "customer_name": "Taylor Valdez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Joshua G.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.9,
        "customer_name": "Lindsey Craig",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Samantha G.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.9,
        "customer_name": "Christian Lewis",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Rebecca W.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.9,
        "customer_name": "Dana Esparza",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Betty C.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.9,
        "customer_name": "George White",
        "service_type": "Garage Door Opener",
        "tech_name": "Pamela D.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.8,
        "customer_name": "Tiffany Walker",
        "service_type": "Garage Door Insulation",
        "tech_name": "Stephen D.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.8,
        "customer_name": "Ashley Alexander",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Erica G.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.9,
        "customer_name": "Kristi Mora",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Lindsay S.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "Erika Burke",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Amber D.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-08",
        "review_rate": 5.0,
        "customer_name": "Krystal Arnold",
        "service_type": "Garage Door Opener",
        "tech_name": "Heidi J.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.9,
        "customer_name": "Katherine Jones",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jason O.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Keith Lee",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Anna S.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.9,
        "customer_name": "Bobby Rodriguez",
        "service_type": "Garage Door Opener",
        "tech_name": "Chelsea H.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-07",
        "review_rate": 5.0,
        "customer_name": "Courtney Peterson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Annette N.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.9,
        "customer_name": "Stacy Scott",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Bryce T.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.9,
        "customer_name": "Rebecca Michael",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Melissa S.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.9,
        "customer_name": "Angela Cruz",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Roger M.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.9,
        "customer_name": "Rebecca Sandoval",
        "service_type": "Garage Door Services",
        "tech_name": "Jacqueline G.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.9,
        "customer_name": "Richard Henderson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Alyssa G.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-10",
        "review_rate": 5.0,
        "customer_name": "Jennifer Huff",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Nancy S.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "Christina Hansen",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Alexander B.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-07",
        "review_rate": 5.0,
        "customer_name": "Michael Thompson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Holly W.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.8,
        "customer_name": "Derek Johnson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Tracey J.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-20",
        "review_rate": 5.0,
        "customer_name": "Andrew Stewart",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jason C.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.9,
        "customer_name": "Jason Hicks",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Miguel B.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Rice",
        "service_type": "Garage Door Off Track",
        "tech_name": "Devin C.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-23",
        "review_rate": 4.8,
        "customer_name": "Jacob Rosales",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jennifer V.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.9,
        "customer_name": "Mary Ray",
        "service_type": "Garage Door Insulation",
        "tech_name": "Donald R.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.9,
        "customer_name": "Michelle Gonzalez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Lisa M.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.9,
        "customer_name": "Thomas Johnson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Lori B.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.9,
        "customer_name": "Kristen Hernandez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Barbara G.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.8,
        "customer_name": "Adrienne Austin",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Daniel G.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-12",
        "review_rate": 5.0,
        "customer_name": "Brandon Conway",
        "service_type": "Garage Door Repair",
        "tech_name": "Sheila M.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.9,
        "customer_name": "Pamela Perez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Dennis C.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-17",
        "review_rate": 5.0,
        "customer_name": "James Foster DVM",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Erin S.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-18",
        "review_rate": 5.0,
        "customer_name": "Jordan Carney",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Christine B.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.9,
        "customer_name": "Karina Chavez DDS",
        "service_type": "Garage Door Repair",
        "tech_name": "Joshua P.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Weaver MD",
        "service_type": "Garage Door Opener",
        "tech_name": "Scott E.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    }
]
export default LocationReviewsData;
