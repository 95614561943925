import React from "react";
import './Blog.scss';
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// images
import telephone from '../../Images/telephone.png'
import book from '../../Images/book.png'
import NavigatorPath from "../../Tools/NavigatorPath/NavigatorPath"


export default function Blog({ FaqList, Blogs }) {
    const location = useLocation()
    const navigate = useNavigate()
    const [getPathValue, setgetPathValue] = useState("");

    const [header, setHeader] = useState(false)
    const listenScrollEvent = () => {
        if (window.scrollY < 50 && window.screen.width < 690) {
            return setHeader(false)
        } else if (window.scrollY > 50 && window.screen.width < 690) {
            return setHeader(true)
        }
    }
    useEffect(() => {
        window.addEventListener('scroll', listenScrollEvent);
        return () =>
            window.removeEventListener('scroll', listenScrollEvent);

    }, [window.scrollY]);

    function readingTime(S) {
        const text = S.Text;
        const wpm = 225;
        const words = text.trim().split(/\s+/).length;
        const time = Math.ceil(words / wpm);
        return time
    }
    // readingTime();

    useEffect(() => {

        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });
        let value = params.category;
        setgetPathValue(params.category ? params.category : null)
    }, []);


    const getSpecificNumberFaq = () => {
        const number = Math.random() * FaqList.length
        if (number <= (FaqList.length - 3) && number > 0) {
            return Math.floor(number)
        } else {
            return getSpecificNumberFaq()
        }
    }
    const [getNumberFaq, setgetNumberFaq] = useState(getSpecificNumberFaq());

    return (

        <div className="Blog">
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>Garage Door Care Blog | Expert Tips & Advice from Santa Monica Garage Door</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content="Garage Door Care Blog | Expert Tips & Advice from Santa Monica Garage Door" data-react-helmet="true" />
                <meta name="og:title" property="og:title" content="Garage Door Care Blog | Expert Tips & Advice from Santa Monica Garage Door" data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* Blog Tags */}
                <meta name="PageType" content="article" />
                <meta name="ContentType" content="article" />
                <meta name="Category" content="article" />
                <meta name="SubCategories" content="article" />
                <meta name="FunnelStage" content="upper" />
                <meta name="Objective" content="education, seo" />

                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content="https://www.garagedoorsantamonica.org/blog" data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content="Explore the Santa Monica Garage Door Blog for expert advice, maintenance tips, and safety insights on garage door care. Stay informed and keep your garage door in top condition with our comprehensive articles." data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content="Explore the Santa Monica Garage Door Blog for expert advice, maintenance tips, and safety insights on garage door care. Stay informed and keep your garage door in top condition with our comprehensive articles." data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content="Garage Door Care Blog | Expert Tips & Advice from Santa Monica Garage Door" data-react-helmet="true" />
                <meta name="keywords" content="Garage Door, Garage Door Installation, Garage Door Inspection, Garage Door Repair, Garage Door Maintenance, Garage Door Opener Installation, Garage Door Off Track Repair, Garage Door Spring Repair, Garage Door Remote Programming, Garage Door Roller Replacement, Garage Door Weather Seals, Garage Door Services, Garage Door Safety, Garage Door Tips, FAQ Page" data-react-helmet="true" />
            </Helmet>
            <div className="BlogHeader">
                <div className="BlogHeaderTop">
                    <h1>Stay Informed & Up-to-Date</h1>
                    <h3>
                        Living in Santa Monica, CA, offers the charm of beautiful weather and the convenience of a reliable garage door system. To ensure your garage door remains safe and efficient, regular maintenance is crucial. Discover why garage door care should be a top priority for every Santa Monica homeowner.
                    </h3>
                    <a href="tel:213-642-2179">CALL 213-642-2179<img src={telephone} alt="Garage Door In Santa Monica, CA | Garage Door Repair In Santa Monica, CA | Garage Door Services In Santa Monica, CA | Garage Door Inspection In Santa Monica, CA" /></a>
                    <a href="/book">SCHEDULE ONLINE<img src={book} alt="Garage Door In Santa Monica, CA | Garage Door Repair In Santa Monica, CA | Garage Door Services In Santa Monica, CA | Garage Door Inspection In Santa Monica, CA" /></a>

                </div>
                <div className="BlogHeaderBottom">
                    <h1>Blogs</h1>
                </div>
            </div>
            <div className="BlogsList">
                {Blogs.filter(a => getPathValue ? a.CategoryPath == getPathValue : a.CategoryPath != getPathValue).reverse().map((A, index) =>
                    <a href={`/blog/${A.BlogPath.replace(/ /g, "_").toLowerCase() + "_" + (A.BlogId - 1)}`} key={index} className="BlogsListBox" >
                        <article>
                            <div className="BlogsListBoxLeft">
                                <img src={A.Photos} alt={`${A.BlogPath} | Santa Monica Garage Door | SENEX | Business | Busniess Laon | ondeck`} />
                            </div>
                            <div className="BlogsListBoxRight">
                                <h1>{A.Title}</h1>
                                <h2>{A.Discription.slice(0, 60 - 1) + "..."}</h2>
                                <span>
                                    <h5>{readingTime(Blogs[index])} MIN READ</h5>
                                </span>
                            </div>
                        </article>

                    </a>
                )}
            </div>
            <div className="BlogsListFaq">
                <div className="BlogsListFaqHead">
                    <h1>Frequently asked questions</h1>
                    <a href="/faq">View All</a>
                </div>
                <div className="BlogsListFaqList">
                    {FaqList.slice(getNumberFaq, (getNumberFaq + 5)).map((A, index) =>
                        <article key={index} className="FaqListBox">
                            <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                <h1>{A.question}</h1>
                                {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                <h3><span> {A.category}</span></h3>
                            </a>
                        </article>

                    )}
                </div>
            </div>

            <NavigatorPath />

        </div >
    )
}

